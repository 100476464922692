import Heading from "@tiptap/extension-heading";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import {
  EditorProvider,
  mergeAttributes,
  useCurrentEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import classNames from "classnames";
import {
  BoldIcon,
  CodeIcon,
  Heading1Icon,
  Heading2Icon,
  Heading3Icon,
  ItalicIcon,
  LinkIcon,
  ListIcon,
  ListOrderedIcon,
  RotateCcwIcon,
  RotateCwIcon,
  StrikethroughIcon,
  UnderlineIcon,
} from "lucide-react";
import React, { useCallback, useMemo } from "react";
import { Button } from "../Button";

type ExtensionsOptions = { placeholder: string };
function getExtensions({ placeholder }: ExtensionsOptions) {
  return [
    Placeholder.configure({
      emptyEditorClass:
        "before:text-secondary before:content-[attr(data-placeholder)] before:float-left before:h-0 before:pointer-events-none",
      placeholder,
    }),
    Underline,
    Link.configure({
      autolink: true,
      defaultProtocol: "https",
      HTMLAttributes: {
        class: "text-primary underline-offset-4 hover:underline cursor-pointer",
      },
    }),
    Heading.extend({
      levels: [1, 2, 3],
      renderHTML({ node, HTMLAttributes }) {
        const level = this.options.levels.includes(node.attrs.level)
          ? node.attrs.level
          : this.options.levels[0];
        const classes: { [index: number]: string } = {
          1: "text-2xl",
          2: "text-xl",
          3: "text-lg",
        };
        return [
          `h${level}`,
          mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
            class: `${classes[level]}`,
          }),
          0,
        ];
      },
    }).configure({ levels: [1, 2, 3] }),
    StarterKit.configure({
      bold: {
        HTMLAttributes: {
          class: "font-semibold",
        },
      },
      bulletList: {
        HTMLAttributes: {
          class: "list-disc",
        },
      },
      orderedList: {
        HTMLAttributes: {
          class: "list-decimal",
        },
      },
      code: {
        HTMLAttributes: {
          class: "font-mono rounded-md bg-gray-100 p-1 text-sm text-slate-700",
        },
      },
      italic: {
        HTMLAttributes: {
          class: "italic",
        },
      },
      heading: false,
    }),
  ];
}

type TipTapEditorProps = {
  editable: boolean;
  content?: string;
  onBlur?: (content: string) => void;
  placeholder: string;
  bgClassName?: string;
};

export default function TipTapEditor({
  editable = true,
  content,
  onBlur,
  placeholder,
  bgClassName,
}: TipTapEditorProps) {
  const parsedContent = useMemo(
    () => getEditorParsedContent(content),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editable]
  );
  const extensions = useMemo(
    () => getExtensions({ placeholder }),
    [placeholder]
  );
  return (
    <EditorProvider
      onBlur={({ editor }) => onBlur?.(JSON.stringify(editor.getJSON()))}
      slotBefore={editable ? <EditorMenu /> : null}
      extensions={extensions}
      content={parsedContent}
      editable={editable}
      editorProps={{
        attributes: {
          class: classNames(
            editable
              ? "tiptap first-child:mt-0 min-h-[60px] w-full rounded-md border border-input px-3 py-2 shadow-sm placeholder:text-secondary focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
              : "tiptap first-child:mt-0 min-h-[60px] w-full rounded-md placeholder:text-secondary focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
            bgClassName == null ? "bg-white" : bgClassName
          ),
        },
      }}
    ></EditorProvider>
  );
}

const getButtonClass = (isActive: boolean) => {
  return `text-black-600 hover:text-black hover:bg-neutral-200/50 hover:shadow ${isActive ? "bg-neutral-100" : ""}`;
};
const getIconClass = (isActive: boolean) => {
  return `w-4 h-4 ${isActive ? "text-black" : "text-gray-600"}`;
};

function EditorMenu() {
  const { editor } = useCurrentEditor();

  const setLink = useCallback(() => {
    if (editor === null) {
      return null;
    }
    const previousUrl = editor.getAttributes("link").href;
    let url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    if (!(url.startsWith("http://") || url.startsWith("https://"))) {
      url = `https://${url}`;
    }
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    console.error("No editor found in context");
    return null;
  }

  const canUndo = editor.can().undo();
  const canRedo = editor.can().redo();

  const isBoldActive = editor.isActive("bold");
  const isItalicActive = editor.isActive("italic");
  const isUnderlineActive = editor.isActive("underline");
  const isStrikethroughActive = editor.isActive("strike");
  const isCodeActive = editor.isActive("code");

  const isBulletListActive = editor.isActive("bulletList");
  const isOrderedListActive = editor.isActive("orderedList");
  const isLinkActive = editor.isActive("link");

  const isHeading1Active = editor.isActive("heading", { level: 1 });
  const isHeading2Active = editor.isActive("heading", { level: 2 });
  const isHeading3Active = editor.isActive("heading", { level: 3 });

  return (
    <div className="flex flex-row gap-1 my-2 flex-wrap">
      <Button
        variant="none"
        size="small"
        icon={<RotateCcwIcon className={getIconClass(canUndo)} />}
        onClick={() => editor.chain().focus().undo().run()}
        className={getButtonClass(false)}
        disabled={!canUndo}
      />
      <Button
        variant="none"
        size="small"
        icon={<RotateCwIcon className={getIconClass(canRedo)} />}
        onClick={() => editor.chain().focus().redo().run()}
        className={getButtonClass(false)}
        disabled={!canRedo}
      />
      <div className="border rounded-md border-gray-100 shrink-0" />
      <Button
        variant="none"
        size="small"
        icon={<Heading1Icon className={getIconClass(isHeading1Active)} />}
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={getButtonClass(isHeading1Active)}
      />
      <Button
        variant="none"
        size="small"
        icon={<Heading2Icon className={getIconClass(isHeading2Active)} />}
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={getButtonClass(isHeading2Active)}
      />
      <Button
        variant="none"
        size="small"
        icon={<Heading3Icon className={getIconClass(isHeading3Active)} />}
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={getButtonClass(isHeading3Active)}
      />
      <div className="border rounded-md border-gray-100 shrink-0" />
      <Button
        variant="none"
        size="small"
        icon={<BoldIcon className={getIconClass(isBoldActive)} />}
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={getButtonClass(isBoldActive)}
      />
      <Button
        variant="none"
        size="small"
        icon={<ItalicIcon className={getIconClass(isItalicActive)} />}
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={getButtonClass(isItalicActive)}
      />
      <Button
        variant="none"
        size="small"
        icon={
          <StrikethroughIcon className={getIconClass(isStrikethroughActive)} />
        }
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className={getButtonClass(isStrikethroughActive)}
      />
      <Button
        variant="none"
        size="small"
        icon={<CodeIcon className={getIconClass(isCodeActive)} />}
        onClick={() => editor.chain().focus().toggleCode().run()}
        className={getButtonClass(isCodeActive)}
      />
      <Button
        variant="none"
        size="small"
        icon={<UnderlineIcon className={getIconClass(isUnderlineActive)} />}
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={getButtonClass(isUnderlineActive)}
      />
      <div className="border rounded-md border-gray-100 shrink-0" />
      <Button
        variant="none"
        size="small"
        icon={<ListIcon className={getIconClass(isBulletListActive)} />}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={getButtonClass(isBulletListActive)}
      />
      <Button
        variant="none"
        size="small"
        icon={<ListOrderedIcon className={getIconClass(isOrderedListActive)} />}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={getButtonClass(isOrderedListActive)}
      />
      <Button
        variant="none"
        size="small"
        icon={<LinkIcon className={getIconClass(isLinkActive)} />}
        onClick={setLink}
        className={getButtonClass(isLinkActive)}
      />
    </div>
  );
}

function getEditorParsedContent(content: string | undefined) {
  let parsedContent = content ?? "";
  if (typeof content === "string") {
    try {
      parsedContent = JSON.parse(content);
    } catch (error) {
      parsedContent = `<p>${content}</p>`;
      console.error(error);
    }
  }
  return parsedContent;
}
