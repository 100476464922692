import classNames from "classnames";
import {
  ChevronLeftIcon,
  MapPinHouseIcon,
  PlusIcon,
  Trash2Icon,
  TrashIcon,
  XIcon,
} from "lucide-react";
import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../common/AlertDialog";
import { Button, getButtonClass } from "../../common/Button";
import { Card } from "../../common/Card";
import TipTapEditor from "../../common/Editor/TipTapEditor";
import { ImageUpload } from "../../common/ImageUpload";
import { Input } from "../../common/Input";
import { Label } from "../../common/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../common/Select";
import {
  getPillColorFromStatus,
  getPilTextColorFromStatus,
  type OnStatus,
} from "../../common/StatusBadge";
import { Text, type TextProps } from "../../common/Text";
import { useWindowSize, WindowSize } from "../../utils/useWindowSize";
import { useLocalProducersContext } from "./LocalProducersContext";
import {
  LocalProducerEditingContextProvider,
  useLocalProducersEditingContext,
} from "./LocalProducersEditingContext";
import type { LocalProducer } from "./localProducerClient";

export default function LocalProducerEditingPage() {
  const navigate = useNavigate();
  const { producerId } = useParams();
  const { producers, loadingState, loadProducers } = useLocalProducersContext();
  const producer = producers.find(p => p.id === producerId);

  useEffect(() => {
    if (loadingState === "ready" && producerId != null && producer == null) {
      console.error("Event not found");
      navigate("/");
      return;
    }

    if (loadingState === "initial") {
      loadProducers();
    }
  }, [producer, loadingState, navigate, producerId, loadProducers]);

  if (loadingState !== "ready") {
    return <div>Se incarca...</div>;
  }

  if (loadingState === "ready" && producerId != null && producer == null) {
    return null;
  }

  return (
    <LocalProducerEditingContextProvider localProducerToEdit={producer}>
      <LocalProducerForm
        isUpdating={producer != null}
        producerId={producerId}
      />
    </LocalProducerEditingContextProvider>
  );
}

type LocalProducerFormProps = {
  isUpdating: boolean;
  producerId: string | undefined;
};

function LocalProducerForm({ isUpdating, producerId }: LocalProducerFormProps) {
  const {
    name,
    setName,
    description,
    setDescription,
    content,
    setContent,
    categories,
    setCategories,
    status,
    setStatus,
    hasDraftState,

    locations,
    setLocations,
    contactDetails,
    setContactDetails,

    finalImagesOrder,
    existingImages,
    imagesToDelete,
    imagesToUpload,
    setImages,

    saveProducer,
  } = useLocalProducersEditingContext();
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  return (
    <div className="grid grid-cols-12 gap-2 py-4 mx-2 min-h-[calc(100vh-10rem)]">
      <SideMenu
        status={status}
        setStatus={setStatus}
        horizontal={windowSize !== WindowSize.Large}
      />
      <Card className="flex flex-col gap-2 col-span-full lg:col-start-4 lg:col-span-6 justify-between border border-blue-500 rounded-md shadow shadow-blue-700/30 bg-white">
        <div className="flex flex-col p-4 gap-y-2">
          <div className="flex flex-row gap-1 items-center pb-4">
            <MapPinHouseIcon className="w-6 h-6 text-blue-950" />
            <Text size="h4" emphasized color="text-blue-950">
              {isUpdating ? "Modifica highlight" : "Adauga highlight"}
            </Text>
          </div>
          {isUpdating ? (
            <div>
              <ImageUpload
                existingImages={existingImages}
                setImages={setImages}
                finalImagesOrder={finalImagesOrder}
                imagesToUpload={imagesToUpload}
                imagesToDelete={imagesToDelete}
              />
            </div>
          ) : null}
          <div>
            <Label htmlFor="title">Denumire</Label>
            <Input
              id="name"
              className="w-full"
              placeholder="Denumirea producatorului"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div>
            <div>
              <Label htmlFor="description">Descriere scurta</Label>
              <Text color="secondary" size="small" className="ml-2">
                (optional)
              </Text>
            </div>
            <Input
              id="description"
              placeholder="O descriere scurta"
              value={description}
              onChange={e => setDescription(e.target.value)}
              className="w-full"
            />
          </div>
          <div>
            <Label htmlFor="description">Continut</Label>
            <TipTapEditor
              editable={true}
              onBlur={setContent}
              content={content}
              placeholder="Prezentare generala a producatorului"
            />
          </div>
          <div>
            <Label htmlFor="description">Categorii</Label>
            <LocalProducerCategories
              categories={categories}
              setCategories={setCategories}
            />
          </div>
          <div>
            <Label htmlFor="description">Date de contact</Label>
            <ContactDetailsSection
              contactDetails={contactDetails}
              setContactDetails={setContactDetails}
            />
          </div>
          <div>
            <Label htmlFor="description">Locatii</Label>
            <ProducerLocations
              locations={locations}
              setLocations={setLocations}
            />
          </div>
        </div>
        <div className="w-full flex flex-row-reverse justify-between gap-2 p-4">
          <Button
            value="Salveaza"
            disabled={!hasDraftState}
            onClick={async () => {
              const savedProducerId = await saveProducer();
              if (savedProducerId != null) {
                navigate(`/iasi/producatori/${savedProducerId}`);
              }
            }}
          />
          <CancelDraftStateAlertDialog
            hasDraftState={hasDraftState}
            producerId={producerId}
          />
        </div>
      </Card>
    </div>
  );
}

type LocalProducerCategoriesProps = {
  categories: LocalProducer["categories"];
  setCategories: (categories: string[]) => void;
};

function LocalProducerCategories({
  categories,
  setCategories,
}: LocalProducerCategoriesProps) {
  const [newCategory, setNewCategory] = React.useState("");
  const canAddCategory = useMemo(() => {
    return (
      !categories
        .map(c => c.toLocaleLowerCase())
        .includes(newCategory.toLocaleLowerCase()) && newCategory !== ""
    );
  }, [categories, newCategory]);
  const handleAddNewCategoryCallback = useCallback(() => {
    setCategories([...categories, newCategory]);
    setNewCategory("");
  }, [categories, setCategories, newCategory]);
  return (
    <div>
      {categories.length === 0 ? (
        <Text size="small" color="secondary" className="mb-2">
          Nicio categorie adaugata
        </Text>
      ) : (
        <div className="flex flex-row gap-1 mb-2">
          {categories.map((category, index) => (
            <LocalProducerCategoryPill
              category={category}
              key={index}
              onDelete={() =>
                setCategories(categories.filter((_, i) => i !== index))
              }
            />
          ))}
        </div>
      )}
      <div className="flex flex-row gap-2">
        <Input
          className="w-32"
          placeholder="Categorie"
          value={newCategory}
          onChange={e => setNewCategory(e.target.value)}
        />
        <Button
          value="Adauga"
          variant="outlineLight"
          disabled={!canAddCategory}
          icon={<PlusIcon className="h-4 w-4" />}
          onClick={handleAddNewCategoryCallback}
        />
      </div>
    </div>
  );
}

export function LocalProducerCategoryPill({
  category,
  onDelete,
  onClick,
  isSelected = false,
  size = "default",
}: {
  category: string;
  onDelete?: () => void;
  onClick?: () => void;
  isSelected?: boolean;
  size?: "small" | "default";
}) {
  return (
    <div
      className={classNames(
        size === "default"
          ? "pl-2 py-0.5 rounded-xl"
          : "px-1 py-0.5 rounded-lg",
        "flex flex-row gap-1 items-center border   overflow-clip",
        onClick != null
          ? " cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 hover:border-blue-400 hover:bg-blue-200"
          : "",
        isSelected
          ? "bg-blue-100 border-blue-300"
          : "bg-gray-100 border-gray-300",
        onDelete != null ? "" : size === "default" ? "pr-2" : ""
      )}
      onClick={onClick}
      role={onClick ? "button" : undefined}
      aria-label={`Category: ${category}`}
    >
      <Text
        color={isSelected ? "text-blue-900" : undefined}
        size={size === "small" ? "small" : undefined}
      >
        {category}
      </Text>
      {onDelete != null ? (
        <Button
          size="none"
          className="h-5 w-5 rounded-md bg-gray-100 hover:bg-gray-200 mr-1"
          variant="none"
          icon={
            <XIcon
              className="w-4 h-4 text-secondary hover:text-black"
              strokeWidth={3}
            />
          }
          onClick={onDelete}
        />
      ) : null}
    </div>
  );
}

type ContactDetailsSectionProps = {
  contactDetails: LocalProducer["contactDetails"];
  setContactDetails: (contactDetails: LocalProducer["contactDetails"]) => void;
  textProps?: TextProps;
};

function ContactDetailsSection({
  contactDetails,
  setContactDetails,
  textProps = {
    size: "small",
    children: undefined,
  },
}: ContactDetailsSectionProps) {
  const handleAddContactDetailCallback = useCallback(() => {
    setContactDetails([
      ...contactDetails,
      { label: "", preview: "", value: "" },
    ]);
  }, [contactDetails, setContactDetails]);

  return (
    <div className="flex flex-col gap-1 items-start">
      <div className="flex flex-row gap-1 items-center">
        <Text className="w-32" {...textProps}>
          Eticheta
        </Text>
        <Text className="w-32" {...textProps}>
          Afisare
        </Text>
        <Text className="flex-grow" {...textProps}>
          Valoare
        </Text>
      </div>
      {contactDetails.length === 0 ? (
        <Text size="small" color="secondary">
          Niciun detaliu de contact adaugat
        </Text>
      ) : (
        contactDetails.map((contactDetail, index) => (
          <div
            key={index}
            className="flex flex-row gap-1 items-center flex-wrap"
          >
            <Input
              className="w-32"
              placeholder="Eticheta"
              value={contactDetail.label}
              onChange={e =>
                setContactDetails(
                  contactDetails.map((cd, i) =>
                    i === index ? { ...cd, label: e.target.value } : cd
                  )
                )
              }
            />
            <Input
              className="w-32"
              placeholder="Afisare"
              value={contactDetail.preview}
              onChange={e =>
                setContactDetails(
                  contactDetails.map((cd, i) =>
                    i === index ? { ...cd, preview: e.target.value } : cd
                  )
                )
              }
            />
            <Input
              className="flex-1 min-w-32"
              placeholder="Valoare"
              value={contactDetail.value}
              onChange={e =>
                setContactDetails(
                  contactDetails.map((cd, i) =>
                    i === index ? { ...cd, value: e.target.value } : cd
                  )
                )
              }
            />
            <Button
              value="Elimina"
              variant="outlineLight"
              icon={<TrashIcon className="h-4 w-4" />}
              onClick={() =>
                setContactDetails(contactDetails.filter((_, i) => i !== index))
              }
            />
          </div>
        ))
      )}
      <Button
        value="Adauga contact"
        variant="outlineLight"
        className="mt-1"
        icon={<PlusIcon className="h-4 w-4" />}
        onClick={handleAddContactDetailCallback}
      />
    </div>
  );
}

type ProducerLocationsProps = {
  locations: LocalProducer["locations"];
  setLocations: (locations: LocalProducer["locations"]) => void;
};

function ProducerLocations({
  locations,
  setLocations,
}: ProducerLocationsProps) {
  const handleAddLocationCallback = useCallback(() => {
    setLocations([
      ...locations,
      {
        address: "",
        city: "",
        contactDetails: [],
        locationOnMap: "",
        name: "",
      },
    ]);
  }, [locations, setLocations]);

  return (
    <div className="flex flex-col gap-2 items-start flex-1">
      {locations.length === 0 ? (
        <Text size="small" color="secondary">
          Nicio locatie adaugata
        </Text>
      ) : (
        locations.map((location, index) => (
          <div
            key={index}
            className="bg-blue-50/40 border border-blue-300 rounded-lg flex flex-col w-full gap-1 p-2"
          >
            <div className="flex flex-row gap-2">
              <div className="flex flex-col gap-1 flex-1">
                <Text size="small">Denumire</Text>
                <Input
                  placeholder="Denumire"
                  className="w-full"
                  value={location.name}
                  onChange={e =>
                    setLocations(
                      locations.map((l, i) =>
                        i === index ? { ...l, name: e.target.value } : l
                      )
                    )
                  }
                />
              </div>
              <div className="flex flex-col gap-1">
                <Text className="w-32" size="small">
                  Oras
                </Text>
                <Input
                  className="w-32"
                  placeholder="Oras"
                  value={location.city}
                  onChange={e =>
                    setLocations(
                      locations.map((l, i) =>
                        i === index ? { ...l, city: e.target.value } : l
                      )
                    )
                  }
                />
              </div>
              <Button
                icon={<Trash2Icon className="w-4 h-4" />}
                value=""
                size="small"
                variant="destructive"
                onClick={() =>
                  setLocations(locations.filter((_, i) => i !== index))
                }
              />
            </div>
            <div className="flex flex-row flex-wrap gap-2">
              <div className="flex-grow">
                <Text size="small">Adresa</Text>
                <Input
                  id="location"
                  className="w-full"
                  placeholder="Adresa locatiei"
                  value={location.address}
                  onChange={e =>
                    setLocations(
                      locations.map((l, i) =>
                        i === index ? { ...l, address: e.target.value } : l
                      )
                    )
                  }
                />
              </div>
              <div className="flex-grow">
                <Text size="small">Pin pe harta</Text>
                <Text color="secondary" size="small" className="ml-1">
                  (ex: pin in Google Maps)
                </Text>
                <Input
                  id="locationOnMap"
                  type="url"
                  className="w-full"
                  placeholder="Link catre locatie pe harta"
                  value={location.locationOnMap}
                  onChange={e =>
                    setLocations(
                      locations.map((l, i) =>
                        i === index
                          ? { ...l, locationOnMap: e.target.value }
                          : l
                      )
                    )
                  }
                />
              </div>
              <ContactDetailsSection
                textProps={{ size: "small", children: null }}
                contactDetails={location.contactDetails}
                setContactDetails={newContactDetails =>
                  setLocations(
                    locations.map((l, i) =>
                      i === index
                        ? { ...l, contactDetails: newContactDetails }
                        : l
                    )
                  )
                }
              />
            </div>
          </div>
        ))
      )}
      <Button
        value="Adauga locatie"
        variant="outlineLight"
        className="mt-1"
        icon={<PlusIcon className="h-4 w-4" />}
        onClick={handleAddLocationCallback}
      />
    </div>
  );
}

type SideMenuProps = {
  setStatus: (status: LocalProducer["status"]) => void;
  status: LocalProducer["status"];
  horizontal?: boolean;
};

function SideMenu({ setStatus, status, horizontal }: SideMenuProps) {
  return (
    <div
      className={classNames(
        "flex gap-2",
        horizontal
          ? "col-span-full lg:col-start-4 lg:col-span-6 justify-end"
          : "col-start-10 row-start-1 row-span-1 flex-col justify-start items-start"
      )}
    >
      <div className="w-[120px]">
        <Select
          onValueChange={(newStatus: LocalProducer["status"]) =>
            setStatus(newStatus)
          }
          value={status}
        >
          <SelectTrigger
            backgroundClass={`${getPillColorFromStatus(status as unknown as OnStatus)} ${getPilTextColorFromStatus(status as unknown as OnStatus)}`}
            sizeClass="h-7"
          >
            <SelectValue placeholder={<Text color="secondary">Status</Text>} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="Draft" selectedValue={status}>
              Draft
            </SelectItem>
            <SelectItem value="Published" selectedValue={status}>
              Published
            </SelectItem>
            <SelectItem value="Archived" selectedValue={status}>
              Archived
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}

type CancelDraftStateAlertDialogProps = {
  hasDraftState: boolean;
  producerId: string | undefined;
};

function CancelDraftStateAlertDialog({
  hasDraftState,
  producerId,
}: CancelDraftStateAlertDialogProps) {
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  if (!hasDraftState) {
    return (
      <Button
        icon={<ChevronLeftIcon className="w-4 h-4" />}
        value="Renunta"
        variant="outlineLight"
        onClick={() => {
          if (producerId != null) {
            return navigate(`/iasi/producatori/${producerId}`);
          }
          window.location.href = "/";
        }}
      />
    );
  }
  return (
    <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <AlertDialogTrigger asChild>
        <Button
          icon={<ChevronLeftIcon className="w-4 h-4" />}
          value="Renunta"
          variant="outlineLight"
        />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Sigur renuntati la formular?</AlertDialogTitle>
          <AlertDialogDescription>
            Datele completate in formular vor fi pierdute si nu pot fi
            recuperate.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Continua completarea</AlertDialogCancel>
          <AlertDialogAction
            onClick={() => {
              window.location.href = "/";
            }}
            className={getButtonClass({ variant: "warning" })}
          >
            Renunta
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
