import classNames from "classnames";
import { ChevronLeftIcon, StarIcon } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../common/AlertDialog";
import { Button, getButtonClass } from "../../common/Button";
import { Card } from "../../common/Card";
import { DatePicker } from "../../common/DatePicker";
import TipTapEditor from "../../common/Editor/TipTapEditor";
import { ImageUpload } from "../../common/ImageUpload";
import { Input } from "../../common/Input";
import { Label } from "../../common/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../common/Select";
import {
  getPillColorFromStatus,
  getPilTextColorFromStatus,
  type OnStatus,
} from "../../common/StatusBadge";
import { Text } from "../../common/Text";
import { useWindowSize, WindowSize } from "../../utils/useWindowSize";
import {
  getNumberImputHandler,
  TODAY_START_AT_MIDNIGHT,
} from "../../utils/utils";
import {
  HighlightEditingContextProvider,
  useHighlightEditingContext,
} from "./HighlightEditingContext";
import { useDayHighlightsContext, useHighlight } from "./HighlightsContext";
import type { DayHighlight } from "./highlightClient";

export function HighlightEditingPage() {
  const navigate = useNavigate();
  const { highlightId } = useParams();
  const { loadingState } = useDayHighlightsContext();
  const existingHighlight = useHighlight(highlightId);
  useEffect(() => {
    if (
      loadingState === "ready" &&
      highlightId != null &&
      existingHighlight == null
    ) {
      console.error("Event not found");
      navigate("/");
    }
  }, [existingHighlight, highlightId, loadingState, navigate]);

  if (loadingState === "loading") {
    return <div>Se incarca...</div>;
  }
  if (
    loadingState === "ready" &&
    highlightId != null &&
    existingHighlight == null
  ) {
    return null;
  }

  const key = highlightId ?? "new";
  return (
    <HighlightEditingContextProvider
      highlightToEdit={existingHighlight}
      key={`context-${key}`}
    >
      <HighlightEditingPageInner
        key={`page-${key}`}
        isUpdating={existingHighlight != null}
      />
    </HighlightEditingContextProvider>
  );
}

type HighlightEditingPageInnerProps = { isUpdating: boolean };

function HighlightEditingPageInner({
  isUpdating,
}: HighlightEditingPageInnerProps) {
  const navigate = useNavigate();

  const {
    title,
    setTitle,
    description,
    setDescription,
    content,
    setContent,
    status,
    setStatus,
    hasDraftState,
    priority,
    setPriority,

    finalImagesOrder,
    existingImages,
    imagesToDelete,
    imagesToUpload,
    setImages,

    saveHighlight,
  } = useHighlightEditingContext();
  const windowSize = useWindowSize();

  const priorityCallback = useMemo(
    () =>
      getNumberImputHandler(
        value => {
          setPriority(value ?? 0);
        },
        { minValue: 0 }
      ),
    [setPriority]
  );

  return (
    <div className="grid grid-cols-12 gap-2 py-4 mx-2 min-h-[calc(100vh-10rem)]">
      <SideMenu
        status={status}
        setStatus={setStatus}
        horizontal={windowSize !== WindowSize.Large}
      />
      <Card className="flex flex-col gap-2 col-span-full lg:col-start-4 lg:col-span-6 justify-between border border-purple-700/30 rounded-md shadow shadow-purple-700/30 bg-white">
        <div className="flex flex-col p-4 gap-y-2">
          <div className="flex flex-row gap-1 items-center pb-4">
            <StarIcon className="w-6 h-6 text-purple-950" />
            <Text size="h4" emphasized color="text-purple-950">
              {isUpdating ? "Modifica highlight" : "Adauga highlight"}
            </Text>
          </div>
          {isUpdating ? (
            <div>
              <ImageUpload
                existingImages={existingImages}
                setImages={setImages}
                finalImagesOrder={finalImagesOrder}
                imagesToUpload={imagesToUpload}
                imagesToDelete={imagesToDelete}
              />
            </div>
          ) : null}
          <div>
            <Label htmlFor="title">Titlu</Label>
            <Input
              id="title"
              className="w-full"
              placeholder="Titlul principal"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </div>
          <div>
            <div>
              <Label htmlFor="short_description">Descriere scurta</Label>
              <Text color="secondary" size="small" className="ml-2">
                (optional)
              </Text>
            </div>
            <Input
              id="short_description"
              placeholder="O descriere scurta"
              value={description}
              onChange={e => setDescription(e.target.value)}
              className="w-full"
            />
          </div>
          <HighlightDateSection />
          <div>
            <Label htmlFor="priority">Prioritate</Label>
            <Input
              id="event-duration"
              value={priority}
              onChange={priorityCallback}
              height="h-[34px]"
              padding="p-1"
              min={0}
              className="w-[66px] text-center w-min-content"
              placeholder={priority.toString()}
            />
          </div>
          <div>
            <Label htmlFor="content">Conținut</Label>
            <TipTapEditor
              editable={true}
              onBlur={setContent}
              content={content}
              placeholder="Continutul acestui highlight de interes"
            />
          </div>
        </div>
        <div className="w-full flex flex-row-reverse justify-between gap-2 p-4">
          <Button
            value="Salveaza"
            disabled={!hasDraftState}
            onClick={async () => {
              const savedHighlightId = await saveHighlight();
              if (savedHighlightId != null) {
                navigate(`/iasi/highlights/${savedHighlightId}`);
              }
            }}
          />
          <CancelDraftStateAlertDialog hasDraftState={hasDraftState} />
        </div>
      </Card>
    </div>
  );
}

function HighlightDateSection() {
  const { startDate, setStartDate, endDate, setEndDate } =
    useHighlightEditingContext();

  const [showStartDateControls, setShowStartDateControls] = useState(false);

  return (
    <div
      className={classNames(
        "flex flex-wrap items-start",
        showStartDateControls ? "flex-row gap-4" : "flex-col gap-1"
      )}
    >
      {showStartDateControls ? (
        <div>
          <div className="flex justify-between items-center">
            <Label htmlFor="date">Data de publicare</Label>
            <Button
              variant="link"
              value={"elimina"}
              size="small"
              className="pr-1"
              onClick={() => {
                setShowStartDateControls(false);
                setStartDate(undefined);
              }}
            />
          </div>
          <div>
            <HighlightDate
              date={startDate ?? new Date()}
              setDate={setStartDate}
            />
          </div>
        </div>
      ) : (
        <Button
          value="Mentioneaza publicare"
          variant="outlineLight"
          size="small"
          onClick={() => {
            setShowStartDateControls(true);
          }}
        />
      )}
      <div>
        <Label htmlFor="date">Data de expirare</Label>
        <div>
          <HighlightDate date={endDate} setDate={setEndDate} />
        </div>
      </div>
    </div>
  );
}

function HighlightDate({
  date,
  setDate,
  fromDate = TODAY_START_AT_MIDNIGHT,
}: {
  fromDate?: Date;
  date: Date;
  setDate: (date: Date) => void;
}) {
  return (
    <DatePicker
      date={date}
      fromDate={fromDate}
      setDate={newDate => setDate(newDate ?? TODAY_START_AT_MIDNIGHT)}
    />
  );
}

type SideMenuProps = {
  setStatus: (status: DayHighlight["status"]) => void;
  status: DayHighlight["status"];
  horizontal?: boolean;
};

function SideMenu({ setStatus, status, horizontal }: SideMenuProps) {
  return (
    <div
      className={classNames(
        "flex gap-2",
        horizontal
          ? "col-span-full lg:col-start-4 lg:col-span-6 justify-end"
          : "col-start-10 row-start-1 row-span-1 flex-col justify-start items-start"
      )}
    >
      <div className="w-[120px]">
        <Select
          onValueChange={(newStatus: DayHighlight["status"]) =>
            setStatus(newStatus)
          }
          value={status}
        >
          <SelectTrigger
            backgroundClass={`${getPillColorFromStatus(status as unknown as OnStatus)} ${getPilTextColorFromStatus(status as unknown as OnStatus)}`}
            sizeClass="h-7"
          >
            <SelectValue placeholder={<Text color="secondary">Status</Text>} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="Draft" selectedValue={status}>
              Draft
            </SelectItem>
            <SelectItem value="Published" selectedValue={status}>
              Published
            </SelectItem>
            <SelectItem value="Archived" selectedValue={status}>
              Archived
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}

type CancelDraftStateAlertDialogProps = { hasDraftState: boolean };

function CancelDraftStateAlertDialog({
  hasDraftState,
}: CancelDraftStateAlertDialogProps) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  if (!hasDraftState) {
    return (
      <Button
        icon={<ChevronLeftIcon className="w-4 h-4" />}
        value="Inapoi"
        variant="outlineLight"
        onClick={() => {
          window.location.href = "/";
        }}
      />
    );
  }
  return (
    <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <AlertDialogTrigger asChild>
        <Button
          icon={<ChevronLeftIcon className="w-4 h-4" />}
          value="Inapoi"
          variant="outlineLight"
        />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Sigur renuntati la formular?</AlertDialogTitle>
          <AlertDialogDescription>
            Datele completate in formular vor fi pierdute si nu pot fi
            recuperate.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Continua completarea</AlertDialogCancel>
          <AlertDialogAction
            onClick={() => {
              window.location.href = "/";
            }}
            className={getButtonClass({ variant: "warning" })}
          >
            Renunta
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
