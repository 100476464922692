import classNames from "classnames";
import { format } from "date-fns";
import { ro } from "date-fns/locale";
import React, { useEffect, useMemo } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { OnStatusBadge } from "../../common/StatusBadge";
import { Text } from "../../common/Text";
import {
  useIsUserAdmin,
  useIsUserFinal,
  useIsUserLoading,
} from "../../user/UserContext";
import { useDayHighlightsContext } from "./HighlightsContext";

export function HighlightsAll() {
  const isUserAdmin = useIsUserAdmin();
  const isUserLoading = useIsUserLoading();
  const isUserFinal = useIsUserFinal();
  const { highlights: highlightsMap, loadFutureHighlights } =
    useDayHighlightsContext();
  const highlights = useMemo(
    () => Array.from(highlightsMap.values()),
    [highlightsMap]
  );

  useEffect(() => {
    loadFutureHighlights();
  }, [loadFutureHighlights]);

  const navigate = useNavigate();

  if (!isUserFinal || isUserLoading) {
    return <PageWrapper>Se încarcă...</PageWrapper>;
  }

  if (isUserFinal && !isUserAdmin) {
    return <Navigate to="/" />;
  }

  return (
    <PageWrapper>
      <div className={classNames("md:col-span-6 md:col-start-4 row-span-1")}>
        <h3 className="text-2xl font-bold m-1">
          Toate secțiunile viitoare de interes
        </h3>
      </div>
      {highlights.map(highlight => {
        const buttonStyles =
          highlight?.media[0]?.url != null
            ? {
                backgroundImage: `url(${highlight.media[0].url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }
            : {};
        return (
          <div
            className={classNames(
              "flex flex-col gap-3",
              "md:col-span-6 md:col-start-4 md:row-span-3 md:grid-cols-subgrid md:grid-rows-subgrid md:grid"
            )}
            key={highlight.id}
          >
            <div className="md:col-span-4 min-h-[18.5rem]">
              <button
                className={classNames(
                  "flex grow w-full text-left min-h-[18.5rem]",
                  "rounded-md border border-purple-500 hover:shadow-purple-500/50 hover:shadow-lg duration-300 transition-all"
                )}
                onClick={() => navigate(`/iasi/highlights/${highlight.id}`)}
                aria-label={`Vezi detalii pentru elementul de interes cu titlul ${highlight.title}`}
                style={buttonStyles}
                role="button"
              >
                <div
                  className={classNames(
                    "flex flex-col gap-1 grow overflow-hidden self-end px-4 py-2 w-full rounded-b-md",
                    "bg-gradient-to-t from-white/70 to-white/40",
                    "backdrop-blur-sm"
                  )}
                >
                  <Text size="h4" emphasized className="on-one-line-text">
                    {highlight.title}
                  </Text>
                  <Text className="on-two-line-text">
                    {highlight.description}
                  </Text>
                </div>
              </button>
            </div>
            <div className="md:col-span-2 flex flex-col px-3 md:px-0 md:pt-3">
              <div className="flex flex-row gap-1 items-baseline">
                <label className="font-bold text-secondary">Id:</label>
                <Text emphasized className="font-mono">
                  {highlight.id}
                </Text>
              </div>
              <div className="flex flex-row gap-1 items-baseline">
                <label className="font-bold text-secondary">Status:</label>
                <OnStatusBadge status={highlight.status} />
              </div>
              <div className="flex flex-row gap-1 items-baseline">
                <label className="font-bold text-secondary">Prima zi:</label>
                {highlight.startDate == null ? (
                  <Text color="secondary" className="font-mono">
                    nedefinit
                  </Text>
                ) : (
                  <Text emphasized>
                    {format(highlight.startDate, "PPP", { locale: ro })}
                  </Text>
                )}
              </div>
              <div className="flex flex-row gap-1 items-baseline">
                <label className="font-bold text-secondary">Ultima zi:</label>
                {highlight.endDate == null ? (
                  <Text color="secondary" className="font-mono">
                    nedefinit
                  </Text>
                ) : (
                  <Text emphasized>
                    {format(highlight.endDate, "PPP", { locale: ro })}
                  </Text>
                )}
              </div>
              <div className="flex flex-row gap-1 items-baseline">
                <label className="font-bold text-secondary">Prioritate:</label>
                <Text emphasized className="font-mono">
                  {highlight.priority}
                </Text>
              </div>
            </div>
          </div>
        );
      })}
    </PageWrapper>
  );
}

type PageWrapperProps = {
  children: React.ReactNode;
};

function PageWrapper({ children }: PageWrapperProps) {
  return (
    <div
      className={classNames(
        "flex flex-col w-full gap-3 px-3 pt-3 pb-4 min-h-[calc(100vh-10rem)]",
        "md:grid md:grid-cols-12 md:auto-rows-[5.7rem]"
      )}
    >
      {children}
    </div>
  );
}
