import { networkClient } from "../../client/networkClient";
import type { OnStatus } from "../../common/StatusBadge";
import type { OnMedia } from "../Events/MediaView";

export type DayHighlight = {
  // identifier
  startDate: Date | undefined;
  endDate: Date;
  id: string;

  // editable
  media: OnMedia[];
  title: string;
  description: string;
  content: string;
  priority: number;
  status: OnStatus;

  // automated
  // updatedAt: Date;
  // createdAt: Date;
};

export type CreateDayHighlightRequestPayload = Omit<
  DayHighlight,
  "id" | "createdAt" | "updatedAt" | "order" | "media"
>;

export const DAY_HIGHLIGHT_PREFIX = "highlights";

class HighlightsClient {
  public async createHighlight(
    highlightPayload: CreateDayHighlightRequestPayload
  ) {
    const newHighlight = await networkClient.post<
      CreateDayHighlightRequestPayload,
      DayHighlight
    >(`/${DAY_HIGHLIGHT_PREFIX}`, highlightPayload);
    parseHighlight(newHighlight);
    return newHighlight;
  }

  public async updateHighlight(
    highlightId: string,
    highlightPayload: CreateDayHighlightRequestPayload
  ) {
    const updatedHighlight = await networkClient.put<
      CreateDayHighlightRequestPayload,
      DayHighlight
    >(`/${DAY_HIGHLIGHT_PREFIX}/${highlightId}`, highlightPayload);
    parseHighlight(updatedHighlight);
    return updatedHighlight;
  }

  public async deleteHighlight(highlightId: string) {
    return networkClient.delete(`/${DAY_HIGHLIGHT_PREFIX}/${highlightId}`);
  }

  public async getHighlightsForDay(date: string) {
    const highlights = await networkClient.get<DayHighlight[]>(
      `/${DAY_HIGHLIGHT_PREFIX}/day/${date}`
    );
    highlights.forEach(parseHighlight);
    return highlights;
  }

  public async getHighlight(highlightId: string) {
    return networkClient.get<DayHighlight>(
      `/${DAY_HIGHLIGHT_PREFIX}/${highlightId}`
    );
  }

  public async getFutureHighlights() {
    const highlights = await networkClient.get<DayHighlight[]>(
      `/${DAY_HIGHLIGHT_PREFIX}`
    );
    highlights.forEach(parseHighlight);
    return highlights;
  }

  public async uploadMedia(highlightId: string, formData: FormData) {
    const highlight = await networkClient.post<FormData, DayHighlight>(
      `/${DAY_HIGHLIGHT_PREFIX}/${highlightId}/media`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    parseHighlight(highlight);
    return highlight;
  }

  public async deleteMedia(highlightId: string, mediaIds: string[]) {
    const highlight = await networkClient.put<
      { mediaIds: string[] },
      DayHighlight
    >(`/${DAY_HIGHLIGHT_PREFIX}/${highlightId}/media/delete`, { mediaIds });
    parseHighlight(highlight);
    return highlight;
  }
}

function parseHighlight(highlight: DayHighlight): DayHighlight {
  highlight.startDate =
    highlight.startDate != null
      ? new Date(highlight.startDate)
      : highlight.startDate;
  highlight.endDate = new Date(highlight.endDate);
  return highlight;
}

export const highlightsClient = new HighlightsClient();
