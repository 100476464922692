import { isEqual } from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { onClient, RegisterProps } from "../client/onClient";

export interface User {
  name: string;
  username: string;
  email: string;
  roles: string[];
}

interface UserContextData {
  user: UserState["user"];
  loadingState: UserState["loadingState"];
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (props: RegisterProps) => Promise<void>;
}

type UserState = {
  user: User | null;
  isLoggedIn: boolean;
  loadingState: "initial" | "loading" | "final";
};

export const UserContext = createContext<UserContextData | null>(null);

interface UserProviderProps {
  children: React.ReactNode;
}

function UserContextProviderInternal({ children }: UserProviderProps) {
  const [userState, setUserState] = useState<UserState>({
    user: null,
    isLoggedIn: false,
    loadingState: "initial",
  });

  const login = useCallback(async (username: string, password: string) => {
    try {
      setUserState(s => ({ ...s, loadingState: "loading" }));
      const response = await onClient.login({ username, password });
      setUserState({
        user: response.user,
        isLoggedIn: true,
        loadingState: "final",
      });
    } catch (error) {
      console.error("Login failed", error);
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      setUserState(s => ({ ...s, loadingState: "loading" }));
      await onClient.logout();
      setUserState({
        user: null,
        isLoggedIn: false,
        loadingState: "final",
      });
    } catch (error) {
      console.error("Logout failed", error);
    }
  }, []);

  const getUserData = useCallback(async () => {
    try {
      const user = await onClient.getUserData();
      if (
        userState.isLoggedIn &&
        isEqual(user, userState.user) &&
        userState.loadingState === "final"
      ) {
        return;
      } else {
        setUserState({
          user,
          isLoggedIn: true,
          loadingState: "final",
        });
      }
    } catch (error) {
      setUserState({
        user: null,
        isLoggedIn: false,
        loadingState: "final",
      });
      console.error("Login check failed", error);
    }
  }, [userState.isLoggedIn, userState.loadingState, userState.user]);

  const register = useCallback(
    async (props: RegisterProps) => {
      await onClient.register(props);
      await getUserData();
    },
    [getUserData]
  );

  useEffect(() => {
    if (!userState.isLoggedIn) {
      return;
    }
    const interval = setInterval(
      async () => {
        try {
          await onClient.checkIsLoggedIn();
        } catch (error) {
          setUserState({
            user: null,
            isLoggedIn: false,
            loadingState: "final",
          });
          console.error("Login check failed", error);
        }
      },
      1000 * 60 * 10
    );

    return () => {
      clearInterval(interval);
    };
  }, [userState.isLoggedIn]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const value = useMemo(
    () =>
      ({
        user: userState.user,
        loadingState: userState.loadingState,
        login,
        logout,
        register,
      }) satisfies UserContextData,
    [login, logout, register, userState.loadingState, userState.user]
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export const UserContextProvider = React.memo(UserContextProviderInternal);

export function useUserContext(): UserContextData {
  const userContext = useContext(UserContext);
  if (userContext == null) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return userContext;
}

export function useIsUserFinal() {
  const { loadingState } = useUserContext();
  return loadingState === "final";
}

export function useIsUserLoading(): boolean {
  const { loadingState } = useUserContext();
  return loadingState === "loading";
}

export function useIsUserAdmin(): boolean {
  const { user } = useUserContext();
  return user?.roles.includes("admin") ?? false;
}

export function useIsUserEditor(): boolean {
  const { user } = useUserContext();
  return user?.roles.includes("editor") ?? false;
}
