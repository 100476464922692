import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  CreateLocalProducerRequestPayload,
  LocalProducer,
  localProducerClient,
} from "./localProducerClient";

interface LocalProducersContextData {
  producers: LocalProducer[];
  loadingState: "initial" | "loading" | "ready";
  loadProducers: () => Promise<void>;
  createProducer: (
    payload: CreateLocalProducerRequestPayload
  ) => Promise<LocalProducer>;
  updateProducer: (
    producerId: string,
    payload: CreateLocalProducerRequestPayload
  ) => Promise<LocalProducer>;
  deleteProducer: (producerId: string) => Promise<void>;
  updateMedia: (
    producerId: string,
    options: {
      newMedia: FormData | null;
      mediaToDelete: string[]; // ids
      mediaOrder: string[]; // names
    }
  ) => Promise<void>;
}

const LocalProducersContext = createContext<LocalProducersContextData | null>(
  null
);

interface LocalProducersContextProviderProps {
  children: React.ReactNode;
}

function LocalProducersContextProviderInternal({
  children,
}: LocalProducersContextProviderProps) {
  const [loadingState, setLoadingState] = useState<
    "initial" | "loading" | "ready"
  >("initial");
  const [producers, setProducers] = useState<LocalProducer[]>([]);

  const loadProducers = useCallback(async () => {
    setLoadingState("loading");
    const newLocalProducers = await localProducerClient.getLocalProducers();
    setProducers(newLocalProducers);
    setLoadingState("ready");
  }, []);

  const createProducer = useCallback(
    async (payload: CreateLocalProducerRequestPayload) => {
      const newProducer =
        await localProducerClient.createLocalProducer(payload);
      setProducers(prev => [...prev, newProducer]);
      return newProducer;
    },
    []
  );

  const updateProducer = useCallback(
    async (producerId: string, payload: CreateLocalProducerRequestPayload) => {
      const updatedProducer = await localProducerClient.updateLocalProducer(
        producerId,
        payload
      );
      setProducers(existingProducers =>
        existingProducers.map(producer =>
          producer.id === producerId ? updatedProducer : producer
        )
      );
      return updatedProducer;
    },
    []
  );

  const deleteProducer = useCallback(async (id: string) => {
    await localProducerClient.deleteLocalProducer(id);
    setProducers(existingProducers =>
      existingProducers.filter(producer => producer.id !== id)
    );
  }, []);

  const updateMedia = useCallback(
    async (
      producerId: string,
      options: {
        newMedia: FormData | null;
        mediaToDelete: string[]; // ids
        mediaOrder: string[]; // names
      }
    ) => {
      const { newMedia, mediaToDelete, mediaOrder } = options;
      let updatedProducer = null;
      if (newMedia != null) {
        updatedProducer = await localProducerClient.uploadMedia(
          producerId,
          newMedia
        );
      }
      if (mediaToDelete.length > 0) {
        updatedProducer = await localProducerClient.deleteMedia(
          producerId,
          mediaToDelete
        );
      }
      if (updatedProducer != null) {
        setProducers(existingProducers =>
          existingProducers.map(producer =>
            producer.id === producerId ? updatedProducer : producer
          )
        );
      }
    },
    []
  );

  const value: LocalProducersContextData = useMemo(() => {
    return {
      producers,
      loadingState,
      loadProducers,
      createProducer,
      updateProducer,
      deleteProducer,
      updateMedia,
    } satisfies LocalProducersContextData;
  }, [
    createProducer,
    loadingState,
    deleteProducer,
    loadProducers,
    producers,
    updateMedia,
    updateProducer,
  ]);

  return (
    <LocalProducersContext.Provider value={value}>
      {children}
    </LocalProducersContext.Provider>
  );
}

export const LocalProducersContextProvider = React.memo(
  LocalProducersContextProviderInternal
);

export const useLocalProducersContext = () => {
  const context = useContext(LocalProducersContext);
  if (!context) {
    throw new Error(
      "useLocalProducersContext must be used within a LocalProducersProvider"
    );
  }
  return context;
};
