import { isEqual } from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import type { FileWithPreview } from "../../common/ImageUpload";
import { OnStatus } from "../../common/StatusBadge";
import type { OnMedia } from "../Events/MediaView";
import { useLocalProducersContext } from "./LocalProducersContext";
import type {
  ContactDetail,
  CreateLocalProducerRequestPayload,
  LocalProducer,
  PhysicalLocation,
} from "./localProducerClient";

interface LocalProducersEditingContextData {
  name: string;
  setName: (name: string) => void;
  description: string;
  setDescription: (desc: string) => void;
  content: string;
  setContent: (content: string) => void;

  contactDetails: ContactDetail[];
  setContactDetails: (details: ContactDetail[]) => void;
  locations: PhysicalLocation[];
  setLocations: (locations: PhysicalLocation[]) => void;

  categories: string[];
  setCategories: (categories: string[]) => void;

  status: OnStatus;
  setStatus: (status: OnStatus) => void;

  hasDraftState: boolean;

  existingImages: OnMedia[];
  finalImagesOrder: string[]; // names
  imagesToUpload: FileWithPreview[];
  imagesToDelete: string[]; // ids
  setImages: (images: FileWithPreview[]) => void;

  saveProducer: () => Promise<string>;
}

const LocalProducersEditingContext =
  createContext<LocalProducersEditingContextData | null>(null);

interface LocalProducersEditingContextProviderProps {
  localProducerToEdit?: LocalProducer | null;
  children: React.ReactNode;
}

function LocalProducerEditingContextProviderInternal({
  children,
  localProducerToEdit,
}: LocalProducersEditingContextProviderProps) {
  const { createProducer, updateProducer, updateMedia } =
    useLocalProducersContext();

  const [name, setName] = useState<string>(localProducerToEdit?.name ?? "");
  const [description, setDescription] = useState<string>(
    localProducerToEdit?.description ?? ""
  );
  const [content, setContent] = useState<string>(
    localProducerToEdit?.content ?? ""
  );
  const [contactDetails, setContactDetails] = useState<ContactDetail[]>(
    localProducerToEdit?.contactDetails ?? []
  );
  const [locations, setLocations] = useState<PhysicalLocation[]>(
    localProducerToEdit?.locations ?? []
  );

  const [categories, setCategories] = useState<string[]>(
    localProducerToEdit?.categories ?? []
  );
  const [status, setStatus] = useState<OnStatus>(
    localProducerToEdit?.status ?? OnStatus.Draft
  );

  const existingImages = useMemo(
    () => localProducerToEdit?.media ?? [],
    [localProducerToEdit?.media]
  );
  const [finalImagesOrder, setFinalImagesOrder] = useState<string[]>(
    existingImages.map(media => media.name)
  );
  const [imagesToUpload, setImagesToUpload] = useState<FileWithPreview[]>([]);
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);

  const setImages = useCallback(
    (images: FileWithPreview[]) => {
      const newImagesOrder = images.map(image => image.name);
      setFinalImagesOrder(newImagesOrder);
      const newImages = images.filter(
        image =>
          !existingImages.some(
            existingImage => existingImage.name === image.name
          )
      );
      setImagesToUpload(newImages);
      setImagesToDelete(
        existingImages
          .filter(existingImage => !newImagesOrder.includes(existingImage.name))
          .map(media => media.id)
      );
    },
    [existingImages]
  );

  const prepareAndUpdateMedia = useCallback(
    async (highlightId: string) => {
      let newMedia: null | FormData = null;
      if (imagesToUpload.length > 0) {
        newMedia = new FormData();
        Array.from(imagesToUpload).forEach(file => {
          newMedia!.append("files", file);
        });
      }
      await updateMedia(highlightId, {
        newMedia,
        mediaToDelete: imagesToDelete,
        mediaOrder: [],
      });
    },
    [imagesToDelete, imagesToUpload, updateMedia]
  );

  const saveProducer = useCallback(async (): Promise<string> => {
    const preparedContactDetails = contactDetails.filter(
      contactDetail => contactDetail.label !== "" && contactDetail.value !== ""
    );

    const payload: CreateLocalProducerRequestPayload = {
      name,
      description,
      content,
      contactDetails: preparedContactDetails,
      locations,
      status,
      categories,
    };

    if (localProducerToEdit) {
      await updateProducer(localProducerToEdit.id, payload);
      await prepareAndUpdateMedia(localProducerToEdit.id);
      return localProducerToEdit.id;
    } else {
      const newProducer = await createProducer(payload);
      return newProducer.id;
    }
  }, [
    name,
    description,
    content,
    contactDetails,
    locations,
    status,
    categories,
    localProducerToEdit,
    updateProducer,
    createProducer,
    prepareAndUpdateMedia,
  ]);

  const hasDraftState = useMemo(() => {
    if (localProducerToEdit == null) {
      return (
        name.length > 0 ||
        description.length > 0 ||
        content.length > 0 ||
        status !== OnStatus.Draft ||
        locations.length > 0 ||
        categories.length > 0 ||
        contactDetails.length > 0
      );
    } else {
      const categoriesHasChanges = !isEqual(
        categories,
        localProducerToEdit.categories
      );

      const contactDetailsHasChanges = !isEqual(
        contactDetails,
        localProducerToEdit.contactDetails
      );
      const locationsHasChanges = !isEqual(
        locations,
        localProducerToEdit.locations
      );

      return (
        name !== localProducerToEdit.name ||
        description !== localProducerToEdit.description ||
        content !== localProducerToEdit.content ||
        status !== localProducerToEdit.status ||
        locationsHasChanges ||
        categoriesHasChanges ||
        imagesToUpload.length > 0 ||
        imagesToDelete.length > 0 ||
        contactDetailsHasChanges
      );
    }
  }, [
    categories,
    contactDetails,
    content,
    description,
    imagesToDelete.length,
    imagesToUpload.length,
    localProducerToEdit,
    locations,
    name,
    status,
  ]);

  const value = useMemo(
    () =>
      ({
        name,
        setName,
        description,
        setDescription,
        content,
        setContent,
        contactDetails,
        setContactDetails,
        locations,
        setLocations,
        categories,
        setCategories,
        status,
        setStatus,

        hasDraftState,

        existingImages,
        finalImagesOrder,
        imagesToUpload,
        imagesToDelete,
        setImages,

        saveProducer,
      }) satisfies LocalProducersEditingContextData,
    [
      name,
      description,
      content,
      contactDetails,
      locations,
      categories,
      status,
      hasDraftState,
      existingImages,
      finalImagesOrder,
      imagesToUpload,
      imagesToDelete,
      setImages,
      saveProducer,
    ]
  );

  return (
    <LocalProducersEditingContext.Provider value={value}>
      {children}
    </LocalProducersEditingContext.Provider>
  );
}

export const LocalProducerEditingContextProvider = React.memo(
  LocalProducerEditingContextProviderInternal
);

export const useLocalProducersEditingContext = () => {
  const context = useContext(LocalProducersEditingContext);
  if (!context) {
    throw new Error(
      "useLocalProducersEditingContext must be used within a LocalProducersEditingProvider"
    );
  }
  return context;
};
