import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../common/Button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../common/Card";
import { Input } from "../common/Input";
import { Label } from "../common/Label";
import { setTabTitle } from "../utils/utils";
import { useUserContext } from "./UserContext";

export default function RegisterPage() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { register } = useUserContext();

  const handleRegister = async () => {
    try {
      await register({ username, password, name, email });
      navigate("/");
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  useEffect(() => {
    setTabTitle("Inregistrare");
  });

  return (
    <div className="w-full h-full flex items-center justify-center">
      <Card className="w-[350px] bg-white">
        <CardHeader>
          <CardTitle>Inregistreaza cont nou</CardTitle>
        </CardHeader>
        <CardContent>
          <form>
            <div className="grid w-full items-center gap-4">
              <div className="flex flex-col space-y-2">
                <Label htmlFor="username">Nume</Label>
                <Input
                  id="name"
                  placeholder="Ana Barbu"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  placeholder="email"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <Label htmlFor="username">Nume de utilizator</Label>
                <Input
                  id="username"
                  placeholder="nume de utilizator, ex. ana14"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                />
                <Label htmlFor="password">Parola</Label>
                <Input
                  id="password"
                  placeholder="parola secreta"
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
            </div>
          </form>
        </CardContent>
        <CardFooter className="flex justify-between">
          <Button
            value="Inapoi"
            variant="secondary"
            onClick={() => navigate("/")}
          />
          <Button value="Inregistreaza" onClick={handleRegister} />
        </CardFooter>
      </Card>
    </div>
  );
}
