import React, { createContext, useContext, useMemo, useState } from "react";
import { setTabTitle } from "../utils/utils";

interface CityContextData {
  city: string | undefined;
  setCity: (city: string | undefined) => void;
}

const CityContext = createContext<CityContextData | null>(null);

interface CityContextProviderProps {
  children: React.ReactNode;
}

function CityContextProviderInternal({ children }: CityContextProviderProps) {
  const [city, setCity] = useState<string | undefined>();

  const handleCityChange = (city: string | undefined) => {
    const acceptedCity = city === "iasi" ? "iasi" : undefined;
    if (acceptedCity != null) {
      setTabTitle(tabTitleToCity[acceptedCity]);
    }
    setCity(acceptedCity);
  };

  const value = useMemo(() => ({ city, setCity: handleCityChange }), [city]);

  return <CityContext.Provider value={value}>{children}</CityContext.Provider>;
}

export const CityContextProvider = React.memo(CityContextProviderInternal);

export function useCityContext() {
  const context = useContext(CityContext);
  if (context == null) {
    throw new Error("useCityContext must be used within a CityContextProvider");
  }
  return context;
}

const tabTitleToCity = {
  iasi: "Iași",
};
