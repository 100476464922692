import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  highlightsClient,
  type CreateDayHighlightRequestPayload,
  type DayHighlight,
} from "./highlightClient";

interface HighlightsContextData {
  // state
  highlights: Map<string, DayHighlight>;
  loadingState: "initial" | "loading" | "ready";

  // loaders
  loadHighlightsForDay: (date: string) => Promise<void>;
  loadFutureHighlights: () => Promise<void>;
  getHighlight: (highlightId: string) => Promise<void>;

  // actions
  createHighlight: (
    highlight: CreateDayHighlightRequestPayload
  ) => Promise<DayHighlight>;
  updateHighlight: (
    highlightId: string,
    highlight: CreateDayHighlightRequestPayload
  ) => Promise<DayHighlight>;
  deleteHighlight: (highlightId: string) => Promise<void>;
  updateMedia: (
    highlightId: string,
    options: {
      newMedia: FormData | null;
      mediaToDelete: string[]; // ids
      mediaOrder: string[]; // names
    }
  ) => Promise<void>;
}

export const HighlightsContext = createContext<HighlightsContextData | null>(
  null
);

interface HighlightsContextProviderProps {
  children: React.ReactNode;
}

function HighlightsContextProviderInternal({
  children,
}: HighlightsContextProviderProps) {
  // state
  const [highlights, setHighlights] = useState<Map<string, DayHighlight>>(
    new Map()
  );
  const [loadingState, setLoadingState] = useState<
    "initial" | "loading" | "ready"
  >("initial");

  const updateStateHighlights = useCallback((newHighlights: DayHighlight[]) => {
    const existingHighlights = new Map();
    newHighlights.forEach(highlight => {
      existingHighlights.set(highlight.id, highlight);
    });
    setHighlights(existingHighlights);
  }, []);

  const updateHighlightWithinState = useCallback(
    (newHighlight: DayHighlight) => {
      const newHighlights = new Map(highlights);
      newHighlights.set(newHighlight.id, newHighlight);
      setHighlights(newHighlights);
    },
    [highlights]
  );

  // actions
  const loadFutureHighlights = useCallback(async () => {
    setLoadingState("loading");
    try {
      const highlights = await highlightsClient.getFutureHighlights();
      updateStateHighlights(highlights);
    } catch (error) {
      console.error("Load highlights failed", error);
    }
    setLoadingState("ready");
  }, [updateStateHighlights]);

  const loadHighlightsForDay = useCallback(
    async (date: string) => {
      setLoadingState("loading");
      try {
        const highlights = await highlightsClient.getHighlightsForDay(date);
        updateStateHighlights(highlights);
      } catch (error) {
        console.error("Load highlights failed", error);
      }
      setLoadingState("ready");
    },
    [updateStateHighlights]
  );

  const getHighlight = useCallback(
    async (highlightId: string) => {
      setLoadingState("loading");
      let highlight = null;
      try {
        highlight = await highlightsClient.getHighlight(highlightId);
      } catch (error) {
        console.error("Get highlight failed", error);
      }

      if (highlight != null) {
        if (highlights.has(highlightId)) {
          updateHighlightWithinState(highlight);
        } else {
          updateStateHighlights([highlight]);
        }
      }

      setLoadingState("ready");
    },
    [highlights, updateHighlightWithinState, updateStateHighlights]
  );

  const createHighlight = useCallback(
    async (highlight: CreateDayHighlightRequestPayload) => {
      const newHighlight = await highlightsClient.createHighlight(highlight);
      updateStateHighlights([newHighlight]);
      return newHighlight;
    },
    [updateStateHighlights]
  );

  const updateHighlight = useCallback(
    async (
      highlightId: string,
      updatedHighlight: CreateDayHighlightRequestPayload
    ) => {
      const highlight = await highlightsClient.updateHighlight(
        highlightId,
        updatedHighlight
      );
      updateHighlightWithinState(highlight);
      return highlight;
    },
    [updateHighlightWithinState]
  );

  const deleteHighlight = useCallback(
    async (highlightId: string) => {
      try {
        await highlightsClient.deleteHighlight(highlightId);
        const newHighlights = new Map(highlights);
        newHighlights.delete(highlightId);
        setHighlights(newHighlights);
      } catch (error) {
        console.error("Delete highlight failed", error);
      }
    },
    [highlights]
  );

  const updateMedia = useCallback(
    async (
      highlightId: string,
      options: {
        newMedia: FormData | null;
        mediaToDelete: string[]; // ids
        mediaOrder: string[]; // names
      }
    ) => {
      const { newMedia, mediaToDelete, mediaOrder } = options;
      let highlight = null;
      if (newMedia != null) {
        highlight = await highlightsClient.uploadMedia(highlightId, newMedia);
      }
      if (mediaToDelete.length > 0) {
        highlight = await highlightsClient.deleteMedia(
          highlightId,
          mediaToDelete
        );
      }
      if (highlight != null) {
        // updateStateHighlights([highlight]);
        updateHighlightWithinState(highlight);
      }
    },
    [updateHighlightWithinState]
  );

  const value = useMemo(() => {
    return {
      highlights,
      loadingState,

      loadFutureHighlights,
      loadHighlightsForDay,
      getHighlight,

      createHighlight,
      updateHighlight,
      deleteHighlight,
      updateMedia,
    } satisfies HighlightsContextData;
  }, [
    createHighlight,
    deleteHighlight,
    getHighlight,
    highlights,
    loadFutureHighlights,
    loadHighlightsForDay,
    loadingState,
    updateHighlight,
    updateMedia,
  ]);

  return (
    <HighlightsContext.Provider value={value}>
      {children}
    </HighlightsContext.Provider>
  );
}

export const HighlightsContextProvider = React.memo(
  HighlightsContextProviderInternal
);

export function useDayHighlightsContext() {
  const context = React.useContext(HighlightsContext);
  if (context === null) {
    throw new Error(
      "useDayHighlights must be used within a HighlightsContextProvider"
    );
  }
  return context;
}

export function useDayHighlights(date: string): DayHighlight[] {
  const { highlights, loadHighlightsForDay } = useDayHighlightsContext();
  const initiatedLoading = useRef(false);
  useEffect(() => {
    if (!initiatedLoading.current) {
      initiatedLoading.current = true;
      loadHighlightsForDay(date);
    }
  }, [date, highlights, loadHighlightsForDay]);

  const dayHighlights = useMemo(() => {
    return Array.from(highlights.values()).sort((a, b) => {
      if (a.priority !== b.priority) {
        return (b.priority ?? 0) - (a.priority ?? 0);
      }
      if (a.endDate.getTime() !== b.endDate.getTime()) {
        return a.endDate.getTime() - b.endDate.getTime();
      }
      if (a.startDate && b.startDate) {
        return a.startDate.getTime() - b.startDate.getTime();
      }
      return 0;
    });
  }, [highlights]);

  return dayHighlights;
}

export function useHighlight(
  highlightId: string | null | undefined
): DayHighlight | null {
  const { highlights, getHighlight } = useDayHighlightsContext();
  const hasHighlight = highlights.has(highlightId ?? "");
  const initiatedLoading = useRef(hasHighlight);

  useEffect(() => {
    if (highlightId == null) {
      return;
    }

    if (!initiatedLoading.current) {
      initiatedLoading.current = true;
      getHighlight(highlightId);
    }
  }, [getHighlight, hasHighlight, highlightId]);

  if (highlightId == null) {
    return null;
  }
  return highlights.get(highlightId) ?? null;
}
