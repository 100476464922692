import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TODAY_START_AT_MIDNIGHT } from "../../utils/utils";
import {
  eventsClient,
  type CreateOnEventRequestPayload,
  type EventSearchFilters,
} from "./eventsClient";
import { OnEvent } from "./OnEvent";

interface EventsContextData {
  events: OnEvent[];
  loadingState: "initial" | "loading" | "ready";
  loadFutureEvents: () => Promise<void>;
  loadPastEvents: () => Promise<void>;
  moreEventsInTheFuture: boolean;
  moreEventsInThePast: boolean;
  getEvents: () => Promise<void>;
  getEvent: (eventId: string) => Promise<OnEvent | null>;
  createEvent: (event: CreateOnEventRequestPayload) => Promise<string | null>;
  updateEvent: (
    eventId: string,
    event: CreateOnEventRequestPayload
  ) => Promise<OnEvent>;
  deleteEvent: (eventId: string) => Promise<void>;
  updateMedia: (
    eventId: string,
    options: {
      newMedia: FormData | null;
      mediaToDelete: string[]; // ids
      mediaOrder: string[]; // names
    }
  ) => Promise<void>;
}

export const EventsContext = createContext<EventsContextData | null>(null);

interface EventsContextProviderProps {
  children: React.ReactNode;
}

function EventsContextProviderInternal({
  children,
}: EventsContextProviderProps) {
  // state
  const [trackedEvents, setTrackedEvents] = useState<Map<string, OnEvent>>(
    new Map()
  );
  const [loadingState, setLoadingState] = useState<
    "initial" | "loading" | "ready"
  >("initial");

  const [searchForwardFilters, setSearchForwardFilters] =
    useState<EventSearchFilters>({
      afterDate: TODAY_START_AT_MIDNIGHT,
      limit: 5,
      skip: 0,
    });
  const [hasMoreForward, setHasMoreForward] = useState(false);

  const [searchBackwardFilters, setSearchBackwardFilters] =
    useState<EventSearchFilters>({
      beforeDate: TODAY_START_AT_MIDNIGHT,
      limit: 5,
      skip: 0,
      order: "desc",
    });
  const [hasMoreBackward, setHasMoreBackward] = useState(true);

  // utils functions
  const stateUpdateEvents = useCallback(
    (eventsToTrack: OnEvent[], overwrite: boolean = false) => {
      const preparedEvents = prepareEvents(eventsToTrack);
      const eventsToUpdate = overwrite
        ? preparedEvents
        : preparedEvents.filter(event => trackedEvents.has(event.id));
      const newTrackEvents = new Map<string, OnEvent>(trackedEvents);
      eventsToUpdate.forEach(event => {
        newTrackEvents.set(event.id, event);
      });
      setTrackedEvents(newTrackEvents);
    },
    [trackedEvents]
  );

  // context functions
  const getEvents = useCallback(async () => {
    try {
      setLoadingState("loading");
      const response = await eventsClient.searchEvents(searchForwardFilters);
      const receivedEvents = prepareEvents(response.events);
      const hasMore = response.hasMore;
      setHasMoreForward(hasMore);

      stateUpdateEvents(receivedEvents, true);
      setLoadingState("ready");
    } catch (error) {
      console.error("Get events failed", error);
    }
  }, [searchForwardFilters, stateUpdateEvents]);

  const loadFutureEvents = useCallback(async () => {
    if (hasMoreForward) {
      const searchParams = { ...searchForwardFilters };
      searchParams.skip = (searchParams.skip ?? 0) + (searchParams.limit ?? 0);
      const response = await eventsClient.searchEvents(searchParams);
      const preparedEvents = prepareEvents(response.events);
      setHasMoreForward(response.hasMore);
      stateUpdateEvents(preparedEvents, true);
      setSearchForwardFilters(searchParams);
    }
  }, [hasMoreForward, searchForwardFilters, stateUpdateEvents]);

  const loadPastEvents = useCallback(async () => {
    if (hasMoreBackward) {
      const response = await eventsClient.searchEvents(searchBackwardFilters);
      const preparedEvents = prepareEvents(response.events);
      setHasMoreBackward(response.hasMore);
      stateUpdateEvents(preparedEvents, true);

      const searchParams = { ...searchBackwardFilters };
      searchParams.skip = (searchParams.skip ?? 0) + (searchParams.limit ?? 0);
      setSearchBackwardFilters(searchParams);
    }
  }, [hasMoreBackward, searchBackwardFilters, stateUpdateEvents]);

  const getEvent = useCallback(async (eventId: string) => {
    try {
      const event = await eventsClient.getEvent(eventId);
      const preparedEvents = prepareEvents([event]);
      return preparedEvents[0];
    } catch (error) {
      console.error("Get event failed", error);
    }
    return null;
  }, []);

  const createEvent = useCallback(
    async (event: CreateOnEventRequestPayload) => {
      try {
        const newEvent = await eventsClient.createEvent(event);
        stateUpdateEvents([newEvent]);
        return newEvent.id;
      } catch (error) {
        console.error("Create event failed", error);
      }
      return null;
    },
    [stateUpdateEvents]
  );

  const updateEvent = useCallback(
    async (
      eventId: string,
      updatedEventPayload: CreateOnEventRequestPayload
    ) => {
      try {
        const updatedEvent = await eventsClient.updateEvent(
          eventId,
          updatedEventPayload
        );
        stateUpdateEvents([updatedEvent]);
        return updatedEvent;
      } catch (error) {
        console.error("Update event failed", error);
        throw error;
      }
    },
    [stateUpdateEvents]
  );

  const deleteEvent = useCallback(
    async (eventId: string) => {
      try {
        await eventsClient.deleteEvent(eventId);
        const newTrackedEvents = new Map<string, OnEvent>(trackedEvents);
        newTrackedEvents.delete(eventId);
        setTrackedEvents(newTrackedEvents);
      } catch (error) {
        console.error("Delete event failed", error);
      }
    },
    [trackedEvents]
  );

  const updateMedia = useCallback(
    async (
      eventId: string,
      options: {
        newMedia: FormData | null;
        mediaToDelete: string[]; // ids
        mediaOrder: string[]; // names
      }
    ) => {
      const { newMedia, mediaToDelete, mediaOrder } = options;
      let event = null;
      try {
        if (mediaToDelete.length > 0) {
          event = await eventsClient.deleteMedia(eventId, mediaToDelete);
        }
        if (newMedia != null) {
          event = await eventsClient.uploadMedia(eventId, newMedia);
        }
        // update final order
        if (event != null) {
          stateUpdateEvents([event]);
        }
      } catch (error) {
        console.error("Upload media failed", error);
        throw error;
      }
    },
    [stateUpdateEvents]
  );

  const hasLoadedEvents = useRef(false);
  useEffect(() => {
    if (!hasLoadedEvents.current) {
      hasLoadedEvents.current = true;
      getEvents();
    }
  }, [getEvents]);

  const value = useMemo(() => {
    return {
      createEvent,
      deleteEvent,
      events: Array.from(trackedEvents.values()),
      getEvent,
      getEvents,
      updateEvent,
      loadingState,
      updateMedia,
      moreEventsInTheFuture: hasMoreForward,
      moreEventsInThePast: hasMoreBackward,
      loadFutureEvents,
      loadPastEvents,
    } satisfies EventsContextData;
  }, [
    createEvent,
    deleteEvent,
    trackedEvents,
    getEvent,
    getEvents,
    updateEvent,
    loadingState,
    updateMedia,
    hasMoreForward,
    hasMoreBackward,
    loadFutureEvents,
    loadPastEvents,
  ]);

  return (
    <EventsContext.Provider value={value}>{children}</EventsContext.Provider>
  );
}

export const EventsContextProvider = React.memo(EventsContextProviderInternal);

export function useEventsContext() {
  const context = useContext(EventsContext);
  if (!context) {
    throw new Error(
      "useEventsContext must be used within a EventsContextProvider"
    );
  }
  return context;
}

function prepareEvents(events: OnEvent[]) {
  return events.map(event => {
    event.startDate = new Date(event.startDate);
    if (event.endDate != null) {
      event.endDate = new Date(event.endDate);
    }
    event.createdAt = new Date(event.createdAt);
    event.updatedAt = new Date(event.updatedAt);
    return event;
  });
}

export function useEvent(eventId: string | null | undefined) {
  const { events, getEvent } = useEventsContext();
  const [currentEvent, setCurrentEvent] = useState(
    events.find(event => event.id === eventId)
  );
  const isIndependentEvent = events.find(event => event.id === eventId) == null;
  const [loadingState, setLoadingState] = useState<"loading" | "ready">(
    currentEvent == null ? "loading" : "ready"
  );

  const loadEvent = useCallback(async () => {
    if (eventId == null) {
      setLoadingState("ready");
      return;
    }
    if (currentEvent != null) {
      return;
    }
    const event = await getEvent(eventId);
    if (event == null) {
      setLoadingState("ready");
      return;
    }

    setCurrentEvent(prepareEvents([event])[0]);
    setLoadingState("ready");
  }, [currentEvent, eventId, getEvent]);

  const handleSetCurrentEvent = useCallback((newEvent: OnEvent) => {
    setCurrentEvent(prepareEvents([newEvent])[0]);
  }, []);

  useEffect(() => {
    if (currentEvent != null) {
      return;
    }
    loadEvent();
  }, [currentEvent, loadEvent]);
  return {
    currentEvent,
    loadingState,
    setCurrentEvent: isIndependentEvent ? handleSetCurrentEvent : undefined,
  };
}
