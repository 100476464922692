import { StoreIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card } from "../../common/Card";
import { TODAY_START_AT_MIDNIGHT } from "../../utils/utils";
import { EventsList } from "../Events";
import type { OnEvent } from "../Events/OnEvent";
import { eventsClient } from "../Events/eventsClient";
import type { OnEntity } from "./OnEntity";
import { entitiesClient } from "./entitiesClient";

export function OrganizerPage() {
  const { organizerId } = useParams();

  const [entity, setEnttiy] = useState<Pick<OnEntity, "id" | "name">>();
  const [events, setEvents] = useState<OnEvent[]>();

  useEffect(() => {
    if (organizerId == null) {
      console.error("unexpected missing organizerId");
      return;
    }

    const callback = async () => {
      if (entity == null) {
        const [entity, searchResults] = await Promise.all([
          entitiesClient.getEntityById(organizerId!),
          eventsClient.searchEvents({
            organizerId: organizerId!,
            afterDate: TODAY_START_AT_MIDNIGHT,
          }),
        ]);
        if (entity == null) {
          console.error("unexpected missing entity");
          setEnttiy({ id: "placedholderID", name: "Placeholder name" });
        }
        setEvents(searchResults.events);
        setEnttiy(entity);
      }
    };
    callback();
  }, [entity, organizerId]);

  return (
    <div className="grid grid-cols-12 w-full py-4 min-h-[calc(100vh-10rem)]">
      <Card className="flex flex-col col-span-full lg:col-span-6 lg:col-start-4 justify-between border border-orange-200 rounded-md shadow shadow-orange-100 bg-white">
        <div className="flex flex-col gap-2 p-4">
          <div className="flex flex-col mb-2">
            <div className="flex flex-row gap-2 items-center">
              <StoreIcon className="w-6 h-6" />
              <h1 className="text-2xl font-semibold">
                {entity?.name ?? "Placeholder name"}
              </h1>
            </div>
            <p className="text-secondary">
              Alte evenimente viitoare organizate de catre{" "}
              <span className="font-semibold">
                {entity?.name ?? "Placeholder name"}
              </span>
            </p>
          </div>
        </div>
      </Card>
      <div className="flex flex-col col-span-full lg:col-span-8 lg:col-start-3 rounded-md mt-4 gap-2">
        <EventsList onEvents={events ?? []} />
      </div>
    </div>
  );
}
