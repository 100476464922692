import classNames from "classnames";
import { MailIcon } from "lucide-react";
import React from "react";
import { Text } from "../common/Text";

export default function ContactUs() {
  return (
    <div
      className={classNames(
        "col-span-full md:col-span-6 md:row-span-5",
        "border border-gray-200 hover:border-slate-500 rounded-md group/header duration-300 bg-slate-100",
        "content-center justify-items-center py-[5%]"
      )}
    >
      <div
        className={classNames(
          "flex flex-col gap-3",
          "justify-center items-center",
          "max-w-[80%] m-auto"
        )}
      >
        <Text color="text-slate-800" className="text-center">
          Dacă ai idei pentru îmbunătățirea informațiilor, sau dorești să ne
          semnalezi orice aspect important, scrie-ne la:
        </Text>
        <div
          onClick={e => e.stopPropagation()}
          className="px-1.5 bg-gray-50 rounded-md transition-colors border border-slate-300 group-hover/header:border-slate-700"
        >
          <a
            href={"mailto:contact@orasulnostru.ro"}
            target="_top"
            className="text-blue-700 transition-colors flex flex-row items-center gap-1"
          >
            <MailIcon className="w-4 h-4 mt-0.5" />
            contact@orasulnostru.ro
          </a>
        </div>
        <Text color="text-slate-800" className="text-center">
          Pentru propuneri de colaborare, inclusiv publicitate, parteneriate
          comerciale sau îmbunătățirea serviciilor, contactează-ne la:
        </Text>
        <div
          onClick={e => e.stopPropagation()}
          className="px-1.5 bg-gray-50 rounded-md transition-colors border border-slate-300 group-hover/header:border-slate-700"
        >
          <a
            href={"mailto:colaborari@orasulnostru.ro"}
            target="_top"
            className="text-blue-700 transition-colors flex flex-row items-center gap-1"
          >
            <MailIcon className="w-4 h-4 mt-0.5" />
            colaborari@orasulnostru.ro
          </a>
        </div>
        <Text color="text-slate-700" className="text-center">
          Împreună facem orașul nostru mai bun!
        </Text>
      </div>
    </div>
  );
}
