import classNames from "classnames";
import {
  BusFrontIcon,
  CableCarIcon,
  MapPinIcon,
  NavigationIcon,
  RotateCwIcon,
  ShipIcon,
  ShoppingCartIcon,
  TrainFrontIcon,
  TrainFrontTunnelIcon,
  TramFrontIcon,
} from "lucide-react";
import React, { useCallback, useMemo, useState } from "react";
import { Button } from "../common/Button";
import { SearchBox } from "../common/SearchBox";
import { Text } from "../common/Text";
import { useIsUserAdmin } from "../user/UserContext";
import { plural } from "../utils/plural";
import { useWindowSize, WindowSize } from "../utils/useWindowSize";
import {
  useTransportMetadataContext,
  type TransportStop,
} from "./Transport/TransportMetadataContext";
import {
  StopStatusDetails,
  useTransportStopStatusContext,
  useUpdateStopIdSearchParams,
} from "./Transport/TransportStopStatusContext";
import { WidgetHeader } from "./WidgetHeader";

export default function PublicTransport() {
  const { stops, routes, trips } = useTransportMetadataContext();
  const {
    selectedStopId,
    setSelectedStopId,
    stopStatuses,
    refreshStopStatuses,
  } = useTransportStopStatusContext();
  const selectedStop = stops.get(selectedStopId)!;

  const groupedStops = useMemo(() => {
    const groups = new Map<string, TransportStop[]>();
    stops.forEach(stop => {
      if (!groups.has(stop.stopName)) {
        groups.set(stop.stopName, []);
      }
      groups.get(stop.stopName)!.push(stop);
    });
    return groups;
  }, [stops]);
  const stopsNames = useMemo(
    () => Array.from(groupedStops.keys()),
    [groupedStops]
  );
  const isUserAdmin = useIsUserAdmin();
  const [searchResults, setSearchResults] = useState<string[]>(stopsNames);
  const stopsWithSameName =
    groupedStops.get(selectedStop?.stopName ?? "") ?? [];

  useUpdateStopIdSearchParams();

  const isMobile = useWindowSize() !== WindowSize.Large;
  const [nrStatusesShown, setNrStatusesShown] = useState<number | null>(
    isMobile ? 7 : null
  );
  const validStopStatuses = useMemo(() => {
    return stopStatuses.filter(stopStatus => {
      const trip = trips.get(stopStatus.tripId);
      if (trip == null) {
        // console.log("Trip not found", stopStatus);
        return false;
      }
      const vehicleStatus = stopStatus.arrivalStatus;
      if (vehicleStatus === "Unknown") {
        return false;
      }
      return true;
    });
  }, [stopStatuses, trips]);

  const stopStatusesToShow = useMemo(() => {
    if (nrStatusesShown == null) {
      return validStopStatuses;
    }
    return validStopStatuses.slice(0, nrStatusesShown);
  }, [nrStatusesShown, validStopStatuses]);

  const deviceSupportsGeolocation = "geolocation" in navigator;
  const getClosestStop = useCallback(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;

          let closestStop: TransportStop | null = null;
          let minDistance = Infinity;

          for (const stop of Array.from(stops.values())) {
            const distance = Math.sqrt(
              Math.pow(stop.latitude - latitude, 2) +
                Math.pow(stop.longitude - longitude, 2)
            );
            if (distance < minDistance) {
              minDistance = distance;
              closestStop = stop;
            }
          }

          if (closestStop != null && closestStop.stopId != null) {
            setSelectedStopId(closestStop.stopId);
          }
        },
        error => {
          console.error("Error getting geolocation: ", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, [stops, setSelectedStopId]);

  return (
    <div
      className={classNames(
        "flex flex-col",
        "col-span-full md:col-span-4 row-span-3",
        "border rounded-md duration-300 group/header",
        "md:bg-white border-green-500 md:border-gray-200 hover:border-green-500"
      )}
    >
      <div
        className={classNames(
          "flex flex-col",
          "shrink-0 p-2 border-b rounded-t-md transition-colors",
          "border-green-500 md:border-gray-200 group-hover/header:border-green-500",
          "bg-green-50 md:bg-white group-hover/header:bg-green-50"
        )}
      >
        <div
          className={classNames("flex flex-row justify-between items-center")}
        >
          <WidgetHeader
            icon={<BusFrontIcon className="w-5 h-5 text-green-900" />}
            color="text-green-900"
          >
            Transport Public{" "}
            <span className="font-normal">- sosiri în stație</span>
          </WidgetHeader>
          {isUserAdmin ? (
            <Button
              icon={<RotateCwIcon className="h-4 w-4 shrink-0 " />}
              value=""
              variant="outlineLight"
              size="small"
              onClick={refreshStopStatuses}
              className="bg-white"
            />
          ) : null}
        </div>
        <div className="flex flex-row gap-2">
          {deviceSupportsGeolocation ? (
            <button
              type="button"
              onClick={getClosestStop}
              className="px-2 border border-green-700/50 rounded-md bg-green-400/10 hover:shadow-green-700/70 hover:shadow-sm"
            >
              <NavigationIcon
                className="h-4 w-4 shrink-0 text-green-700/80"
                fill="currentColor"
              />
            </button>
          ) : null}

          <SearchBox
            inputIcon={
              <MapPinIcon className="h-4 w-4 shrink-0 text-secondary" />
            }
            emptyResultsMessage="Nu s-au gasit statii."
            searchBoxPlaceholder="Cauta statie..."
            results={searchResults.map(stopName => ({
              displayName: stopName,
              id: groupedStops.get(stopName)![0].stopId!,
            }))}
            selectedValue={{
              id: selectedStop?.stopId ?? 7,
              displayName: selectedStop?.stopName ?? "Piata Unirii",
            }}
            onValueChange={(newSearchValue: string) => {
              const regex = new RegExp(
                newSearchValue
                  .split("")
                  .map(char => `${char}`)
                  .join(".*"),
                "i"
              );
              const results = stopsNames.filter(stopName =>
                regex.test(
                  stopName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                )
              );
              setSearchResults(results);
            }}
            onValueSelection={(stopId: number) => {
              setSelectedStopId(stopId);
            }}
          />
          {stopsWithSameName.map(stop => (
            <Button
              aria-selected={selectedStopId === stop.stopId}
              variant="outlineLight"
              key={stop.stopId}
              value={stop.alias}
              onClick={() => setSelectedStopId(stop.stopId)}
            />
          ))}
        </div>
      </div>
      <div className="grid on-transport-grid w-full gap-2 px-2 border-b border-b-gray-300 font-semibold">
        <div></div>
        <Text className="justify-self-center font-mon" color="secondary">
          Nr
        </Text>
        <Text color="secondary">Direcție</Text>
        <Text className="justify-self-end" color="secondary">
          Estimare
        </Text>
      </div>
      <div className="flex flex-col w-full overflow-clip min-h-0 rounded-md">
        <div className="flex flex-col w-full overflow-y-scroll">
          {stopStatusesToShow.map((stopStatus, idx) => {
            const trip = trips.get(stopStatus.tripId);
            if (trip == null) {
              // console.log("Trip not found", stopStatus);
              return null;
            }
            const route = routes.get(trip.routeId!);
            const vehicleStatus = stopStatus.arrivalStatus;
            if (vehicleStatus === "Unknown") {
              return null;
            }

            const vehicleType = stopStatus.vehicleType;
            return (
              <div
                key={idx}
                className={classNames(
                  "grid on-transport-grid gap-2 px-2 py-1",
                  {
                    "bg-gray-100": idx % 2 !== 0,
                  }
                )}
              >
                <div
                  className={classNames("text-secondary justify-self-center")}
                >
                  {vehicleType === 0 ? (
                    <TramFrontIcon className="text-blue-400" />
                  ) : vehicleType === 1 ? (
                    <TrainFrontTunnelIcon />
                  ) : vehicleType === 2 ? (
                    <TrainFrontIcon />
                  ) : vehicleType == 3 ? (
                    <BusFrontIcon className="text-green-600" />
                  ) : vehicleType === 4 ? (
                    <ShipIcon />
                  ) : vehicleType === 5 ? (
                    <CableCarIcon />
                  ) : (
                    <ShoppingCartIcon />
                  )}
                </div>
                <div
                  className={classNames(
                    "font-semibold text-gray-700 justify-self-center font-mono"
                  )}
                >
                  {route?.routeShortName}
                </div>
                <div className={classNames("flex-1", {})}>
                  {trip?.tripHeadsign}
                </div>
                <div
                  className={classNames(
                    "flex flex-row justify-self-end gap-1 shrink-0"
                  )}
                >
                  {stopStatus.details === StopStatusDetails.NewOnRoute ? (
                    <div className="shrink-0 text-secondary text-sm self-center">
                      (la capat)
                    </div>
                  ) : null}
                  <div className="font-semibold text-gray-700 shrink-0">
                    {vehicleStatus === "Arrived"
                      ? "În stație"
                      : vehicleStatus === "Arriving"
                        ? "Ajunge"
                        : `${stopStatus.remainingStops} ${plural(stopStatus.remainingStops, "stație", "stații")}`}
                  </div>
                </div>
              </div>
            );
          })}
          {nrStatusesShown != null &&
          validStopStatuses.length > nrStatusesShown ? (
            <div className="w-full flex justify-center py-1">
              <Button
                value="arata mai multe"
                size="small"
                variant="outlineLight"
                onClick={() => {
                  setNrStatusesShown(nrStatusesShown + 5);
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
