import classNames from "classnames";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Text } from "../common/Text";
import { useUserContext } from "../user/UserContext";
import { ScrollToTop } from "../utils/ScrollToTop";
import PublicAnnouncements from "../widgets/ContactUs";
import { Events } from "../widgets/Events";
import LocalProducers from "../widgets/LocalProducers";
import LocationAnchor from "../widgets/LocationAnchor";
import PublicTransport from "../widgets/PublicTransport";
import Search from "../widgets/Search";
import TodayHighlights from "../widgets/TodayHighlights";
import Weather from "../widgets/Weather/Weather";

export function HomePage() {
  return (
    <div
      className={classNames(
        "flex flex-col w-full gap-3 px-3 pt-3 pb-4 min-h-[calc(100vh-10rem)]",
        "md:grid md:grid-cols-12 md:auto-rows-[5.5rem]"
      )}
    >
      <Events />
      <PublicTransport />
      <TodayHighlights />
      <LocalProducers />
      <PublicAnnouncements />
    </div>
  );
}

export function HomeLayout({ children }: { children: React.ReactNode }) {
  const { user } = useUserContext();

  const header = useMemo(() => {
    return (
      <div
        className={classNames(
          user == null
            ? "grid-rows-header-mobile-1"
            : "grid-rows-header-mobile-2",
          "md:grid-rows-header",
          "grid grid-cols-12 w-full row-span-1 bg-slate-700 py-1"
        )}
      >
        <ScrollToTop />
        <LocationAnchor />
        <Search />
        <Weather />
      </div>
    );
  }, [user]);

  const footer = useMemo(() => {
    return (
      <div
        className={classNames(
          "h-[4.5rem] flex flex-row w-full md:h-24 bg-slate-700 py-1 items-center justify-center"
        )}
      >
        <Link to="/despre">
          <div className="inline-flex items-center justify-center whitespace-nowrap focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none gap-1 shrink-0">
            <Text color="text-white">Despre</Text>
          </div>
        </Link>
      </div>
    );
  }, []);

  return (
    <div
      className={classNames(
        "gap-2 h-full overflow-y-auto transition-all duration-300"
      )}
    >
      {header}
      {children}
      {footer}
    </div>
  );
}
