import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import Path108 from "../assets/path108.svg";
import { Text } from "../common/Text";
import { useUserContext } from "../user/UserContext";
import { useCityFromPath } from "../utils/useCityFromPath";

export default function LocationAnchor() {
  const { user } = useUserContext();
  const hasUser = user != null;
  const navigate = useNavigate();
  const city = useCityFromPath();
  const hasCity = city === "iasi";

  return (
    <div
      className={classNames(
        "col-span-6 row-span-1 row-start-1",
        hasUser && "md:col-span-5 md:row-span-1 md:row-start-1",
        !hasUser && "md:col-span-6 md:row-span-1 md:row-start-1",
        "ml-2"
      )}
    >
      <div className="flex flex-1 flex-row gap-1 self-start items-center h-full">
        <button
          onClick={() => navigate("/iasi")}
          value="Iași Orașul Nostru"
          className={classNames(
            "rounded-md hover:bg-gray-400/10 py-1 px-3 transition-colors duration-300"
          )}
        >
          {hasCity ? (
            <div className="flex flex-row gap-1">
              <Path108
                width={30}
                height={60}
                strokeWidth={4}
                opacity={0.8}
                fillOpacity={0}
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="none"
                strokeOpacity={1}
              />
              <div className="flex flex-col items-start">
                <Text color="white" size="h2">
                  Iași
                </Text>
                <Text color="text-gray-300" className="font-bold">
                  Orașul Nostru
                </Text>
              </div>
            </div>
          ) : (
            <Text color="text-gray-300" className="font-bold" size="h4">
              Orașul Nostru
            </Text>
          )}
        </button>
      </div>
    </div>
  );
}
