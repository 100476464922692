import "./styles/input.css";

import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import App from "./App";
import { AboutUsPage } from "./pages/AboutUsPage";
import { CityPageWrapper } from "./pages/CityPageWrapper";
import ErrorPage from "./pages/ErrorPage";
import { HomePage } from "./pages/HomePage";
import LoginPage from "./user/Login";
import RegisterPage from "./user/Register";
import { OrganizerPage } from "./widgets/Entity/OrganizerPage";
import { EventEditingPage } from "./widgets/Events/EventEditingPage";
import { EventPage } from "./widgets/Events/EventPage";
import { HighlightEditingPage } from "./widgets/Highlights/HighlightEditingPage";
import { HighlightPage } from "./widgets/Highlights/HighlightPage";
import { HighlightsAll } from "./widgets/Highlights/HighligtsAll";
import LocalProducerEditingPage from "./widgets/LocalProducers/LocalProducerEditingPage";
import LocalProducerPage from "./widgets/LocalProducers/LocalProducerPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: ":city",
        element: <CityPageWrapper />,
        children: [
          {
            path: "",
            element: <HomePage />,
          },
          {
            path: "evenimente/adauga",
            element: <EventEditingPage />,
          },
          {
            path: "evenimente/:eventId",
            element: <EventPage />,
          },
          {
            path: "evenimente/:eventId/modifica",
            element: <EventEditingPage />,
          },
          {
            path: "organizatori/:organizerId",
            element: <OrganizerPage />,
          },
          {
            path: "highlights",
            element: <HighlightsAll />,
          },
          {
            path: "highlights/adauga",
            element: <HighlightEditingPage />,
          },
          // {
          //   path: "highlights/zi/:date",
          //   element: <HomePage />,
          // },
          {
            path: "highlights/:highlightId",
            element: <HighlightPage />,
          },
          {
            path: "highlights/:highlightId/modifica",
            element: <HighlightEditingPage />,
          },
          {
            path: "producatori/adauga",
            element: <LocalProducerEditingPage />,
          },
          {
            path: "producatori/:producerId/modifica",
            element: <LocalProducerEditingPage />,
          },
          {
            path: "producatori/:producerId",
            element: <LocalProducerPage />,
          },
        ],
      },
      {
        path: "login",
        element: <LoginPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "register",
        element: <RegisterPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "despre",
        element: <AboutUsPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "",
        element: <Navigate to="/iasi" />,
      },
    ],
  },
]);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
