import React, { useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { LogLevel, UserEventId, webLogger } from "./logging/webLogger";
import { CityContextProvider } from "./pages/CityContext";
import { HomeLayout } from "./pages/HomePage";
import { UserContextProvider } from "./user/UserContext";
import { EventsContextProvider } from "./widgets/Events/EventsContext";
import { HighlightsContextProvider } from "./widgets/Highlights/HighlightsContext";
import { LocalProducersContextProvider } from "./widgets/LocalProducers/LocalProducersContext";
import { TransportMetadataContextProvider } from "./widgets/Transport/TransportMetadataContext";
import { TransportStopStatusContextProvider } from "./widgets/Transport/TransportStopStatusContext";

export default function App() {
  const hasLogged = useRef(false);

  const location = useLocation();
  useEffect(() => {
    if (!hasLogged.current) {
      const fullUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;
      hasLogged.current = true;
      webLogger.log(LogLevel.Info, "App mounted", {
        event: UserEventId.APP_LOADED,
        location: fullUrl,
      });
    }
  }, [location.hash, location.pathname, location.search]);

  return (
    <CityContextProvider>
      <UserContextProvider>
        <EventsContextProvider>
          <HighlightsContextProvider>
            <TransportMetadataContextProvider>
              <TransportStopStatusContextProvider>
                <LocalProducersContextProvider>
                  <HomeLayout>
                    <Outlet />
                  </HomeLayout>
                </LocalProducersContextProvider>
              </TransportStopStatusContextProvider>
            </TransportMetadataContextProvider>
          </HighlightsContextProvider>
        </EventsContextProvider>
      </UserContextProvider>
    </CityContextProvider>
  );
}
