import { networkClient } from "../../client/networkClient";
import type { OnStatus } from "../../common/StatusBadge";
import type { OnMedia } from "../Events/MediaView";

export type ContactDetail = {
  label: string;
  preview: string; // text user sees
  value: string; // underlying value
};

export type PhysicalLocation = {
  name: string;
  address: string;
  city: string;
  locationOnMap?: string; // link
  contactDetails: ContactDetail[];
};

export type LocalProducer = {
  id: string;
  name: string;
  description: string;
  content: string;
  createdAt: Date;
  updatedAt: Date;
  media: OnMedia[];
  contactDetails: ContactDetail[];
  locations: PhysicalLocation[];
  categories: string[];
  status: OnStatus;
};

export type CreateLocalProducerRequestPayload = Omit<
  LocalProducer,
  "id" | "createdAt" | "updatedAt" | "media"
>;

export const LOCAL_PRODUCER_PREFIX = "producers";

class LocalProducerClient {
  public async createLocalProducer(payload: CreateLocalProducerRequestPayload) {
    return networkClient.post<CreateLocalProducerRequestPayload, LocalProducer>(
      `/${LOCAL_PRODUCER_PREFIX}`,
      payload
    );
  }

  public async updateLocalProducer(
    producerId: string,
    payload: CreateLocalProducerRequestPayload
  ) {
    return networkClient.put<CreateLocalProducerRequestPayload, LocalProducer>(
      `/${LOCAL_PRODUCER_PREFIX}/${producerId}`,
      payload
    );
  }

  public async deleteLocalProducer(producerId: string) {
    return networkClient.delete(`/${LOCAL_PRODUCER_PREFIX}/${producerId}`);
  }

  public async getLocalProducers() {
    return networkClient.get<LocalProducer[]>(`/${LOCAL_PRODUCER_PREFIX}`);
  }

  public async getLocalProducer(producerId: string) {
    return networkClient.get<LocalProducer>(
      `/${LOCAL_PRODUCER_PREFIX}/${producerId}`
    );
  }

  public async uploadMedia(producerId: string, formData: FormData) {
    return networkClient.post<FormData, LocalProducer>(
      `/${LOCAL_PRODUCER_PREFIX}/${producerId}/media`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  }

  public async deleteMedia(producerId: string, mediaIds: string[]) {
    return networkClient.put<{ mediaIds: string[] }, LocalProducer>(
      `/${LOCAL_PRODUCER_PREFIX}/${producerId}/media/delete`,
      { mediaIds }
    );
  }
}

export const localProducerClient = new LocalProducerClient();
