import classNames from "classnames";
import {
  MapPin,
  MapPinHouseIcon,
  MapPinIcon,
  PenIcon,
  Trash2Icon,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../common/AlertDialog";
import { Button, getButtonClass } from "../../common/Button";
import { Card } from "../../common/Card";
import TipTapEditor from "../../common/Editor/TipTapEditor";
import { OnLink } from "../../common/OnLink";
import { OnStatusBadge, type OnStatus } from "../../common/StatusBadge";
import { Text } from "../../common/Text";
import { useIsUserAdmin } from "../../user/UserContext";
import { useWindowSize, WindowSize } from "../../utils/useWindowSize";
import { MediaView } from "../Events/MediaView";
import { ContactDetailsList } from "../LocalProducers";
import type { LocalProducer } from "./localProducerClient";
import { LocalProducerCategoryPill } from "./LocalProducerEditingPage";
import { useLocalProducersContext } from "./LocalProducersContext";

export default function LocalProducerPage() {
  const { producerId } = useParams();
  const { producers, loadProducers, loadingState } = useLocalProducersContext();
  const producer = producers.find(p => p.id === producerId);

  const isUserAdmin = useIsUserAdmin();

  useEffect(() => {
    if (loadingState === "initial") {
      loadProducers();
    }
  }, [producer, loadProducers, loadingState]);

  if ((loadingState === "ready" && producer == null) || producerId == null) {
    return <Navigate to="/iasi" />;
  }

  if (loadingState !== "ready" || producer == null) {
    return <div>Se incarca datele</div>;
  }
  const hasLocations = producer.locations.length > 0;
  const categories = producer.categories;
  return (
    <div className="grid grid-cols-12 gap-2 py-4 mx-2 min-h-[calc(100vh-10rem)]">
      {isUserAdmin ? (
        <EditProducerMenu producerId={producerId} status={producer.status} />
      ) : null}
      <Card className="flex flex-col gap-2 col-span-full lg:col-start-4 lg:col-span-6 border border-blue-400 rounded-md shadow shadow-blue-700/30 bg-white">
        <MediaView media={producer.media} />
        <div className="flex flex-col gap-2 p-4">
          <div className="flex flex-col mb-2">
            <div className="flex flex-row gap-2 items-center">
              <MapPinHouseIcon className="w-6 h-6" />
              <h1 className="text-2xl font-semibold">{producer.name}</h1>
            </div>
            <Text color="secondary">{producer.description}</Text>
          </div>
          <div className="flex flex-col border border-input bg-neutral-50/40 rounded-md px-4 py-2 self-start">
            <ContactDetailsList
              contactDetails={producer.contactDetails}
              showLabel={true}
            />
          </div>
          {producer.content.length > 0 ? (
            <TipTapEditor
              editable={false}
              content={producer.content}
              placeholder="Acest highlight nu are nicio descriere"
            />
          ) : null}
          <div className="flex flex-row items-center gap-2">
            <Text emphasized color="secondary">
              Categorii:
            </Text>
            {categories.map(category => (
              <LocalProducerCategoryPill key={category} category={category} />
            ))}
          </div>
          {hasLocations ? (
            <div className="flex flex-col mb-2">
              <div className="flex flex-row gap-1 items-center mb-2">
                <MapPin className="w-4 h-4" />
                <Text size="h5" emphasized>
                  Locatii: {producer.locations.length}
                </Text>
              </div>
              <div className="flex flex-col gap-2">
                <ProducerLocationsList locations={producer.locations} />
              </div>
            </div>
          ) : null}
        </div>
      </Card>
    </div>
  );
}

type EditProducerMenuProps = {
  producerId: string;
  status: LocalProducer["status"];
};

function EditProducerMenu({ producerId, status }: EditProducerMenuProps) {
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const isHorizontal = windowSize !== WindowSize.Large;
  const { deleteProducer } = useLocalProducersContext();
  return (
    <div
      className={classNames(
        "flex gap-2",
        isHorizontal
          ? "col-span-full lg:col-start-4 lg:col-span-6 justify-end"
          : "col-start-10 row-start-1 row-span-1 flex-col justify-start items-start"
      )}
    >
      <OnStatusBadge status={status as unknown as OnStatus} />
      <Button
        icon={<PenIcon className="w-4 h-4" />}
        value="Modifica"
        size="small"
        variant="outlineLight"
        onClick={() =>
          navigate("/iasi/producatori/" + producerId + "/modifica")
        }
      />
      <DeleteProducerAlertDialog
        onConfirm={() => {
          deleteProducer(producerId);
          window.location.href = "/";
        }}
      />
    </div>
  );
}

type DeleteProducerAlertDialogProps = {
  onConfirm: () => void;
};

function DeleteProducerAlertDialog({
  onConfirm,
}: DeleteProducerAlertDialogProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return (
    <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <AlertDialogTrigger asChild>
        <Button
          icon={<Trash2Icon className="w-4 h-4" />}
          value="Sterge"
          size="small"
          variant="destructive"
          onClick={() => setIsDialogOpen(true)}
        />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Sigur doresti sa stergi acest producator?
          </AlertDialogTitle>
          <AlertDialogDescription>
            Datele pierdute in urma stergerii nu vor putea fi recuperate.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Renunta</AlertDialogCancel>
          <AlertDialogAction
            onClick={onConfirm}
            className={getButtonClass({ variant: "destructive" })}
          >
            Sterge
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function ProducerLocationsList({
  locations,
}: {
  locations: LocalProducer["locations"];
}) {
  return (
    <>
      {locations.map((location, index) => {
        return (
          <div
            key={index}
            className="bg-blue-50/40 border border-blue-300 rounded-lg flex flex-col w-full gap-1 p-3"
          >
            <div className="flex flex-row items-center justify-between">
              <Text emphasized size="h5">
                {location.name}
              </Text>
              <Text size="h5" emphasized color="secondary">
                {location.city}
              </Text>
            </div>
            <div className="flex flex-row items-center gap-1">
              <Text emphasized color="secondary">
                Adresa:
              </Text>
              {location.locationOnMap != null ? (
                <OnLink
                  href={location.locationOnMap}
                  icon={<MapPinIcon className="w-4 h-4 shrink-0" />}
                  value={location.address}
                  target="_blank"
                />
              ) : (
                <>
                  <MapPinIcon className="w-4 h-4 text-secondary shrink-0" />
                  <Text>{location.address}</Text>
                </>
              )}
            </div>
            <ContactDetailsList
              contactDetails={location.contactDetails}
              showLabel={true}
            />
          </div>
        );
      })}
    </>
  );
}
