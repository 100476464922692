import classNames from "classnames";
import { PlusIcon, StarIcon } from "lucide-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../common/Button";
import { OnStatus } from "../common/StatusBadge";
import { Text } from "../common/Text";
import { LogLevel, UserEventId, webLogger } from "../logging/webLogger";
import { useIsUserAdmin } from "../user/UserContext";
import { TODAY_START_AT_MIDNIGHT } from "../utils/utils";
import type { DayHighlight } from "./Highlights/highlightClient";
import { getHighlightDateFromDate } from "./Highlights/HighlightEditingContext";
import { useDayHighlights } from "./Highlights/HighlightsContext";
import { WidgetHeader } from "./WidgetHeader";

const todayDateForHighlights = getHighlightDateFromDate(
  TODAY_START_AT_MIDNIGHT
);

export default function TodayHighlights() {
  const isUserAdmin = useIsUserAdmin();
  const highlights = useDayHighlights(todayDateForHighlights);
  const navigate = useNavigate();
  const [itemIndexToShow, setItemIndexToShow] = React.useState<number>(0);
  const highlightToShow: DayHighlight | undefined = highlights[itemIndexToShow];
  const nothingToShow = highlightToShow == null;
  const showHighlightSelectors = highlights.length > 1;
  const buttonStyles =
    highlightToShow?.media[0]?.url != null
      ? {
          backgroundImage: `url(${highlightToShow.media[0].url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }
      : {};

  useEffect(() => {
    webLogger.log(LogLevel.Info, "Shown highlight", {
      event: UserEventId.HIGHLIGHT_SHOWN,
      highlightId: highlightToShow?.id,
    });
  }, [highlightToShow?.id]);
  return (
    <div
      className={classNames(
        "flex flex-col",
        "col-span-full md:col-span-4 md:row-span-4 rounded-md group/header duration-300",
        "bg-white border",
        "border-purple-500 md:border-gray-200 hover:border-purple-500"
      )}
    >
      <div
        className={classNames(
          "flex flex-col",
          "shrink-0 p-2 border-b rounded-t-md transition-colors",
          "border-purple-500 md:border-gray-200 group-hover/header:border-purple-500",
          "bg-purple-50 md:bg-white group-hover/header:bg-purple-50"
        )}
      >
        <div
          className={classNames("flex flex-row justify-between items-center")}
        >
          <WidgetHeader
            icon={<StarIcon className="w-5 h-5 text-purple-950" />}
            color="text-purple-950"
          >
            De interes
          </WidgetHeader>
          {isUserAdmin ? (
            <Button
              icon={<PlusIcon className="h-4 w-4 shrink-0 " />}
              value=""
              size="small"
              variant="outlineLight"
              onClick={() => {
                navigate("/iasi/highlights/adauga");
              }}
              className="bg-white"
            />
          ) : null}
        </div>
        {showHighlightSelectors ? (
          <div className="flex flex-row gap-2">
            {highlights.map((highlight, index) => (
              <button
                type="button"
                key={highlight.id}
                value={index + 1}
                onClick={() => {
                  setItemIndexToShow(index);
                }}
                className={classNames(
                  "inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 gap-1",
                  "text-black hover:bg-gray-100 aria-selected:bg-gray-200 hover:shadow border h-7 text-sm px-2.5 py-1",
                  highlight.status === OnStatus.Draft ? "text-orange-600" : "",
                  {
                    "bg-purple-50 border-purple-400": index === itemIndexToShow,
                    "bg-white": index !== itemIndexToShow,
                  }
                )}
              >
                {index + 1}
              </button>
            ))}
            {isUserAdmin ? (
              <Button
                value={"Toate"}
                variant="outlineLight"
                size="small"
                className="ml-auto"
                onClick={() => navigate(`/iasi/highlights`)}
              />
            ) : null}
          </div>
        ) : null}
      </div>
      {nothingToShow ? (
        <div className="min-h-[22rem]"></div>
      ) : (
        <button
          className="flex grow w-full text-left rounded-b-md min-h-[18rem]  "
          onClick={() => navigate(`/iasi/highlights/${highlightToShow.id}`)}
          aria-label={`Vezi detalii pentru elementul de interes cu titlul ${highlightToShow.title}`}
          style={buttonStyles}
          role="button"
        >
          <div
            className={classNames(
              "flex flex-col gap-1 grow overflow-hidden self-end px-4 py-2 w-full rounded-b-md",
              "bg-gradient-to-t from-white/70 to-white/40",
              // "bg-gradient-to-t from-purple-400/30 to-white/30",
              // "bg-gradient-to-t from-black/80 from-0% via-gray-500/40 via-80% to-white/5 to-95%",
              "backdrop-blur-sm"
            )}
          >
            <Text size="h4" emphasized className="on-one-line-text">
              {highlightToShow.title}
            </Text>
            <Text className="on-two-line-text">
              {highlightToShow.description}
            </Text>
          </div>
        </button>
      )}
    </div>
  );
}
