export const TODAY_START_AT_MIDNIGHT = new Date();
TODAY_START_AT_MIDNIGHT.setHours(0, 0, 0, 0);

export const TODAY_END_AT_MIDNIGHT = new Date();
TODAY_END_AT_MIDNIGHT.setHours(23, 59, 59, 0);

const APP_TITLE = "Orașul Nostru";
export function setTabTitle(title: string | undefined) {
  if (title == null) {
    document.title = `${APP_TITLE}`;
  } else {
    document.title = `${APP_TITLE} | ${title}`;
  }
}

export function getNumberImputHandler(
  setter: (value: number | undefined) => void,
  { minValue, maxValue }: { minValue?: number; maxValue?: number } = {}
) {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replaceAll(/\D/g, "");
    let usedValue =
      value.length === 0 ? undefined : Math.floor(parseInt(value));

    if (usedValue === undefined) {
      setter(undefined);
      return;
    }
    if (maxValue !== undefined) {
      usedValue = Math.min(usedValue, maxValue);
    }
    if (minValue !== undefined) {
      usedValue = Math.max(minValue, usedValue);
    }
    setter(usedValue);
  };
}
