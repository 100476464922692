import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useCityFromPath } from "../utils/useCityFromPath";
import { useCityContext } from "./CityContext";

export function CityPageWrapper() {
  const city = useCityFromPath();
  const { setCity } = useCityContext();

  useEffect(() => {
    setCity(city);
    return () => setCity(undefined);
  }, [city, setCity]);

  return <Outlet />;
}
