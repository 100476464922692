import { format } from "date-fns";
import { ro } from "date-fns/locale";
import React, { createContext, useCallback, useMemo, useState } from "react";
import type { FileWithPreview } from "../../common/ImageUpload";
import { OnStatus } from "../../common/StatusBadge";
import { TODAY_END_AT_MIDNIGHT } from "../../utils/utils";
import type { OnMedia } from "../Events/MediaView";
import {
  type CreateDayHighlightRequestPayload,
  type DayHighlight,
} from "./highlightClient";
import { useDayHighlightsContext } from "./HighlightsContext";

type HighlightEditingContextData = {
  startDate: Date | undefined;
  setStartDate: (date: Date | null | undefined) => void;
  endDate: Date;
  setEndDate: (date: Date) => void;
  title: string;
  setTitle: (title: string) => void;
  description: string;
  setDescription: (description: string) => void;
  content: string;
  setContent: (content: string) => void;
  status: OnStatus;
  setStatus: (status: OnStatus) => void;
  priority: number;
  setPriority: (priority: number) => void;

  hasDraftState: boolean;

  existingImages: OnMedia[];
  finalImagesOrder: string[]; // names
  imagesToUpload: FileWithPreview[];
  imagesToDelete: string[]; // ids
  setImages: (images: FileWithPreview[]) => void;

  saveHighlight: () => Promise<string>;
};

export const HighlightEditingContext =
  createContext<HighlightEditingContextData | null>(null);

interface HighlightEditingContextProviderProps {
  highlightToEdit?: DayHighlight | null;
  children: React.ReactNode;
}

export function HighlightEditingContextProviderInternal({
  children,
  highlightToEdit,
}: HighlightEditingContextProviderProps) {
  const { createHighlight, updateHighlight, updateMedia } =
    useDayHighlightsContext();

  const [startDate, setHighlightStartDate] = useState<Date | undefined>(
    undefined
  );
  const setStartDate = useCallback((date: Date | null | undefined) => {
    if (date != null) {
      date.setHours(0, 0, 0, 0); // set to midnight start of the day
      setHighlightStartDate(date);
    } else {
      setHighlightStartDate(undefined);
    }
  }, []);

  const [endDate, setHighlightEndDate] = useState<Date>(() => {
    return highlightToEdit?.endDate != null
      ? highlightToEdit?.endDate
      : TODAY_END_AT_MIDNIGHT;
  });
  const setEndDate = useCallback((date: Date) => {
    date.setHours(23, 59, 59, 0); // set to midnight end of the day
    setHighlightEndDate(date);
  }, []);

  const [title, setTitle] = useState<string>(highlightToEdit?.title ?? "");
  const [description, setDescription] = useState<string>(
    highlightToEdit?.description ?? ""
  );
  const [content, setContent] = useState<string>(
    highlightToEdit?.content ?? ""
  );
  const [status, setStatus] = useState<OnStatus>(
    highlightToEdit?.status ?? OnStatus.Draft
  );
  const [priority, setPriority] = useState<number>(
    highlightToEdit?.priority ?? 0
  );

  const existingImages = useMemo(
    () => highlightToEdit?.media ?? [],
    [highlightToEdit?.media]
  );
  const [finalImagesOrder, setFinalImagesOrder] = useState<string[]>(
    existingImages.map(media => media.name)
  );
  const [imagesToUpload, setImagesToUpload] = useState<FileWithPreview[]>([]);
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);

  const setImages = useCallback(
    (images: FileWithPreview[]) => {
      const newImagesOrder = images.map(image => image.name);
      setFinalImagesOrder(newImagesOrder);
      const newImages = images.filter(
        image =>
          !existingImages.some(
            existingImage => existingImage.name === image.name
          )
      );
      setImagesToUpload(newImages);
      setImagesToDelete(
        existingImages
          .filter(existingImage => !newImagesOrder.includes(existingImage.name))
          .map(media => media.id)
      );
    },
    [existingImages]
  );

  const hasDraftState = useMemo(() => {
    if (highlightToEdit == null) {
      return (
        title.length > 5 &&
        description.length > 0 &&
        content.length > 0 &&
        endDate != null &&
        status != null
      );
    } else {
      return (
        title !== highlightToEdit.title ||
        description !== highlightToEdit.description ||
        content !== highlightToEdit.content ||
        startDate !== highlightToEdit.startDate ||
        endDate !== highlightToEdit.endDate ||
        status !== highlightToEdit.status ||
        imagesToUpload.length > 0 ||
        imagesToDelete.length > 0 ||
        priority !== highlightToEdit.priority
      );
    }
  }, [
    content,
    priority,
    startDate,
    endDate,
    description,
    highlightToEdit,
    imagesToDelete.length,
    imagesToUpload.length,
    status,
    title,
  ]);

  const prepareAndUpdateMedia = useCallback(
    async (highlightId: string) => {
      let newMedia: null | FormData = null;
      if (imagesToUpload.length > 0) {
        newMedia = new FormData();
        Array.from(imagesToUpload).forEach(file => {
          newMedia!.append("files", file);
        });
      }
      await updateMedia(highlightId, {
        newMedia,
        mediaToDelete: imagesToDelete,
        mediaOrder: [],
      });
      // console.log({ event });
    },
    [imagesToDelete, imagesToUpload, updateMedia]
  );

  const saveHighlight = useCallback(async () => {
    if (!hasDraftState) {
      throw new Error("No changes to save");
    }

    const payloadPayload = {
      startDate,
      endDate,
      title,
      description,
      content,
      status,
      priority,
    } satisfies CreateDayHighlightRequestPayload;

    if (highlightToEdit == null) {
      const newHighlight = await createHighlight(payloadPayload);
      return newHighlight.id;
    } else {
      await updateHighlight(highlightToEdit.id, payloadPayload);
      await prepareAndUpdateMedia(highlightToEdit.id);
      return highlightToEdit.id;
    }
  }, [
    hasDraftState,
    startDate,
    priority,
    endDate,
    title,
    description,
    content,
    status,
    highlightToEdit,
    createHighlight,
    updateHighlight,
    prepareAndUpdateMedia,
  ]);

  const value = useMemo(() => {
    return {
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      title,
      setTitle,
      description,
      setDescription,
      content,
      setContent,
      status,
      setStatus,
      priority,
      setPriority,

      hasDraftState,

      existingImages,
      finalImagesOrder,
      imagesToUpload,
      imagesToDelete,
      setImages,
      saveHighlight,
    } satisfies HighlightEditingContextData;
  }, [
    content,
    description,
    endDate,
    existingImages,
    finalImagesOrder,
    hasDraftState,
    imagesToDelete,
    imagesToUpload,
    saveHighlight,
    setEndDate,
    setImages,
    setStartDate,
    startDate,
    status,
    title,
    priority,
    setPriority,
  ]);

  return (
    <HighlightEditingContext.Provider value={value}>
      {children}
    </HighlightEditingContext.Provider>
  );
}

export const HighlightEditingContextProvider = React.memo(
  HighlightEditingContextProviderInternal
);

export function useHighlightEditingContext() {
  const context = React.useContext(HighlightEditingContext);
  if (context == null) {
    throw new Error(
      "useHighlightEditingContext must be used within a HighlightEditingContextProvider"
    );
  }
  return context;
}

export function getHighlightDateFromDate(date: Date) {
  return format(date, "yyyy-MM-dd", { locale: ro });
}
