import classNames from "classnames";
import React, { useEffect } from "react";
import TipTapEditor from "../common/Editor/TipTapEditor";
import { setTabTitle } from "../utils/utils";

const PAGE_CONTENT = `{"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","marks":[{"type":"bold"}],"text":"Orașul Nostru"},{"type":"text","text":" grupează informații relevante despre orașele în care trăim. Aceste informații sunt prezentate într-un mod specific, ce profită de tipul informației, pentru a oferi o interfață relevantă. Formatul pentru fiecare oraș este unul comun pentru o explorare cât mai usoară."}]},{"type":"paragraph"},{"type":"paragraph","content":[{"type":"text","text":"Viziunea este de a construi o platformă ce îmbină eficient viața orașului cu mediul online. Tehnologia trebuie să îmbunătațească viața, nu să o înlocuiască."}]},{"type":"paragraph"},{"type":"paragraph","content":[{"type":"text","marks":[{"type":"bold"}],"text":"La bază stau 3 principii:"}]},{"type":"orderedList","attrs":{"start":1},"content":[{"type":"listItem","content":[{"type":"paragraph","content":[{"type":"text","text":"Extinderea categoriilor de informații accesibile"}]}]},{"type":"listItem","content":[{"type":"paragraph","content":[{"type":"text","text":"îmbunătățirea modului de consum al informațiilor"}]}]},{"type":"listItem","content":[{"type":"paragraph","content":[{"type":"text","text":"Conectarea mediului fizic cu cel online"}]}]}]},{"type":"paragraph"},{"type":"paragraph","content":[{"type":"text","marks":[{"type":"italic"}],"text":"[Ianuarie, 2025]"}]},{"type":"paragraph","content":[{"type":"text","text":"Primul oraș pentru care platforma oferă informații este Iași. Platforma fiind în dezvoltare, unele informații pot fi incomplete sau incorecte."}]},{"type":"paragraph"}]}`;
const bgClassName = "bg-slate-100";

export function AboutUsPage() {
  useEffect(() => {
    setTabTitle("Despre");
  });
  return (
    <div className="w-full min-h-[calc(100vh-10rem)] grid grid-cols-12 py-4 bg-slate-600">
      <div
        className={classNames(
          "flex flex-col gap-2 col-span-full lg:col-start-4 lg:col-span-6 rounded-md p-4 mx-2 border border-slate-400 shadow-md shadow-slate-400/90",
          bgClassName
        )}
      >
        <h1 className="text-2xl font-bold ">
          Despre <span className="text-gray-500">Orașul Nostru</span>
        </h1>
        <TipTapEditor
          editable={false}
          // onBlur={content => console.log(content)}
          content={PAGE_CONTENT}
          placeholder="Evenimentul nu are nicio descriere"
          bgClassName={bgClassName}
        />
      </div>
    </div>
  );
}
