import classNames from "classnames";
import { PenIcon, StarIcon, Trash2Icon } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../common/AlertDialog";
import { Button, getButtonClass } from "../../common/Button";
import { Card } from "../../common/Card";
import TipTapEditor from "../../common/Editor/TipTapEditor";
import { OnStatusBadge, type OnStatus } from "../../common/StatusBadge";
import { Text } from "../../common/Text";
import { LogLevel, UserEventId, webLogger } from "../../logging/webLogger";
import { useIsUserAdmin } from "../../user/UserContext";
import { useWindowSize, WindowSize } from "../../utils/useWindowSize";
import { MediaView } from "../Events/MediaView";
import { useDayHighlightsContext, useHighlight } from "./HighlightsContext";
import type { DayHighlight } from "./highlightClient";

export function HighlightPage() {
  const navigate = useNavigate();
  const { highlightId } = useParams();
  const { loadingState } = useDayHighlightsContext();
  const isUserAdmin = useIsUserAdmin();
  const currentHighlight = useHighlight(highlightId);
  useEffect(() => {
    if (
      loadingState === "ready" &&
      highlightId != null &&
      currentHighlight == null
    ) {
      console.error("Highligh not found not found");
      navigate("/");
    }
  }, [currentHighlight, highlightId, loadingState, navigate]);

  const hasLogged = useRef(false);
  useEffect(() => {
    if (!hasLogged.current) {
      hasLogged.current = true;
      webLogger.log(LogLevel.Info, "Event page loaded", {
        event: UserEventId.HIGHLIGHT_PAGE_LOADED,
        highlightId,
      });
    }
  }, [highlightId]);

  if (loadingState === "loading") {
    return <div>Se incarca...</div>;
  }

  if (currentHighlight == null) {
    return null;
  }

  return (
    <div className="grid grid-cols-12 gap-2 py-4 mx-2 min-h-[calc(100vh-10rem)]">
      {isUserAdmin ? (
        <EditHighlightMenu
          highlightId={currentHighlight.id}
          status={currentHighlight.status}
        />
      ) : null}
      <Card className="flex flex-col gap-2 col-span-full lg:col-start-4 lg:col-span-6 border border-purple-700/30 rounded-md shadow shadow-purple-700/30 bg-white">
        <MediaView media={currentHighlight.media} />
        <div className="flex flex-col gap-2 p-4">
          <div className="flex flex-col mb-2">
            <div className="flex flex-row gap-2 items-center">
              <StarIcon className="w-6 h-6" />
              <h1 className="text-2xl font-semibold">
                {currentHighlight?.title ?? "Placeholder title"}
              </h1>
            </div>
            <Text color="secondary">
              {currentHighlight?.description ??
                "One line description of the highlight"}
            </Text>
          </div>
          {currentHighlight == null ? (
            <div>Se pregateste descrierea...</div>
          ) : (
            <TipTapEditor
              editable={false}
              content={currentHighlight?.content}
              placeholder="Acest highlight nu are nicio descriere"
            />
          )}
          {isUserAdmin ? (
            <div className="flex flex-row gap-1 border-t border-t-slate-400/40 pt-2">
              <Text color="secondary" emphasized>
                Prioritate:
              </Text>
              <Text emphasized>
                {currentHighlight?.priority ?? "Prioritate default"}
              </Text>
            </div>
          ) : null}
        </div>
      </Card>
    </div>
  );
}

type DeleteHighlightAlertDialogProps = {
  onConfirm: () => void;
};

function DeleteHighlightAlertDialog({
  onConfirm,
}: DeleteHighlightAlertDialogProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return (
    <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <AlertDialogTrigger asChild>
        <Button
          icon={<Trash2Icon className="w-4 h-4" />}
          value="Sterge"
          size="small"
          variant="destructive"
          onClick={() => setIsDialogOpen(true)}
        />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Sigur doresti sa stergi acest highlight?
          </AlertDialogTitle>
          <AlertDialogDescription>
            Datele pierdute in urma stergerii nu vor putea fi recuperate.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Renunta</AlertDialogCancel>
          <AlertDialogAction
            onClick={onConfirm}
            className={getButtonClass({ variant: "destructive" })}
          >
            Sterge
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

type EditHighlightMenuProps = {
  highlightId: string;
  status: DayHighlight["status"];
};

function EditHighlightMenu({ highlightId, status }: EditHighlightMenuProps) {
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const isHorizontal = windowSize !== WindowSize.Large;
  const { deleteHighlight } = useDayHighlightsContext();
  return (
    <div
      className={classNames(
        "flex gap-2",
        isHorizontal
          ? "col-span-full lg:col-start-4 lg:col-span-6 justify-end"
          : "col-start-10 row-start-1 row-span-1 flex-col justify-start items-start"
      )}
    >
      <OnStatusBadge status={status as unknown as OnStatus} />
      <Button
        icon={<PenIcon className="w-4 h-4" />}
        value="Modifica"
        size="small"
        variant="outlineLight"
        onClick={() =>
          navigate("/iasi/highlights/" + highlightId + "/modifica")
        }
      />
      <DeleteHighlightAlertDialog
        onConfirm={() => {
          deleteHighlight(highlightId);
          window.location.href = "/";
        }}
      />
    </div>
  );
}
