import classNames from "classnames";
import {
  ImageIcon,
  MailIcon,
  MapPinHouseIcon,
  PhoneOutgoingIcon,
  SquareArrowOutUpRightIcon,
} from "lucide-react";
import React, { useEffect, useMemo, useState, type ReactNode } from "react";
import { Link } from "react-router-dom";
import { Button } from "../common/Button";
import { OnLink } from "../common/OnLink";
import { Text } from "../common/Text";
import { useIsUserAdmin } from "../user/UserContext";
import { camelCase } from "../utils/camelCase";
import { plural } from "../utils/plural";
import { useWindowSize, WindowSize } from "../utils/useWindowSize";
import type {
  ContactDetail,
  LocalProducer,
} from "./LocalProducers/localProducerClient";
import { LocalProducerCategoryPill } from "./LocalProducers/LocalProducerEditingPage";
import { useLocalProducersContext } from "./LocalProducers/LocalProducersContext";
import { WidgetHeader } from "./WidgetHeader";

export default function LocalProducers() {
  const isUserAdmin = useIsUserAdmin();
  const { producers, loadProducers, loadingState } = useLocalProducersContext();
  const categories = producers.flatMap(producer => producer.categories);
  const [selectedCategory, setSelectedCategory] = useState<string>("Toate");
  const selectedProducers = useMemo(() => {
    if (selectedCategory === "Toate") {
      return producers;
    }
    return producers.filter(producer =>
      producer.categories.includes(selectedCategory)
    );
  }, [producers, selectedCategory]);

  useEffect(() => {
    if (loadingState === "initial") {
      loadProducers();
    }
  }, [loadProducers, loadingState]);

  const dataNotLoadedYet =
    loadingState === "initial" || loadingState === "loading";

  return (
    <div
      className={classNames(
        "flex flex-col",
        "col-span-full md:col-span-6 md:row-span-5",
        "border rounded-md group/header duration-300",
        "border-blue-400 md:border-gray-200 hover:border-blue-400"
      )}
    >
      <div
        className={classNames(
          "flex flex-col justify-between rounded-t-md overflow-clip gap-1",
          "shrink-0 p-2 lg:h-[5.4rem] border-b transition-all ",
          "border-blue-400 bg-blue-50/70 md:bg-white md:border-gray-200 group-hover/header:bg-blue-50/70 group-hover/header:border-blue-400"
        )}
      >
        <div
          className={classNames(
            "flex flex-row justify-between items-center",
            "shrink-0"
          )}
        >
          <WidgetHeader
            color="text-blue-950"
            icon={<MapPinHouseIcon className="w-5 h-5 text-blue-950" />}
          >
            Producători Locali
          </WidgetHeader>
          {isUserAdmin ? (
            <Link to="/iasi/producatori/adauga">
              <Button
                value="Adauga Producator"
                size="small"
                variant="outlineLight"
              />
            </Link>
          ) : null}
        </div>
        <div className="flex flex-row items-center gap-x-2 gap-y-1 flex-wrap">
          <Text emphasized color="secondary" className="pl-1">
            Categorie:
          </Text>
          <LocalProducerCategoryPill
            key={-1}
            category={"Toate"}
            isSelected={"Toate" === selectedCategory}
            onClick={() => setSelectedCategory("Toate")}
          />
          {categories.map(category => (
            <LocalProducerCategoryPill
              key={category}
              category={category}
              isSelected={category === selectedCategory}
              onClick={() => setSelectedCategory(category)}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-grow flex-col overflow-y-scroll gap-3 p-3">
        {dataNotLoadedYet ? (
          <Text color="secondary"> Se incarca datele </Text>
        ) : (
          selectedProducers.map(producer => (
            <LocalProducerEntry key={producer.id} producer={producer} />
          ))
        )}
      </div>
    </div>
  );
}

type LocalProducerEntryProps = {
  producer: LocalProducer;
};

function LocalProducerEntry({ producer }: LocalProducerEntryProps) {
  const previewImage = producer.media[0];
  const hasImage = previewImage != null;
  const contactDetails = producer.contactDetails;
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize !== WindowSize.Large;
  return (
    <Link to={`/iasi/producatori/${producer.id}`} className="last:mb-2">
      <div className="bg-white p-2 rounded-xl shadow-sm hover:shadow-lg transition-all flex flex-col gap-2">
        <div className="flex flex-row gap-3">
          <div className="w-28 h-28 rounded-xl shrink-0">
            {hasImage ? (
              <img
                className={"w-full h-full object-cover rounded-lg"}
                src={previewImage.url}
                alt={previewImage.name}
              />
            ) : (
              <ImageIcon className="w-full h-full text-secondary/20" />
            )}
          </div>
          <div className="min-w-30% flex flex-col md:gap-1 flex-1">
            <Text emphasized size="h5">
              {producer.name}
            </Text>
            <Text> {producer.description} </Text>
            <div className="flex flex-row gap-1 mt-1">
              {producer.categories.map(category => (
                <LocalProducerCategoryPill
                  key={category}
                  category={category}
                  isSelected={false}
                  size="small"
                />
              ))}
            </div>
            {producer.locations.length > 0 ? (
              <div className="flex flex-row gap-1">
                <Text color="secondary">{producer.locations.length}</Text>
                <Text color="secondary">
                  {plural(producer.locations.length, "locatie", "locatii")}
                </Text>
              </div>
            ) : null}
          </div>
          {isSmallScreen ? null : (
            <div className="flex flex-col gap-1 items-end min-w-32">
              <ContactDetailsList
                contactDetails={contactDetails}
                onlyWebsites={true}
              />
            </div>
          )}
        </div>
        {isSmallScreen ? (
          <div className="flex flex-col gap-1">
            <ContactDetailsList
              onlyWebsites={true}
              contactDetails={contactDetails}
              showLabel={true}
            />
          </div>
        ) : null}
      </div>
    </Link>
  );
}

export function ContactDetailsList({
  contactDetails,
  showLabel = false,
  onlyWebsites = false,
}: {
  contactDetails: ContactDetail[];
  showLabel?: boolean;
  onlyWebsites?: boolean;
}) {
  const valueWithLabelComponent = (
    label: string,
    value: ReactNode,
    key: string | number
  ) => {
    return (
      <div className="flex flex-row items-center flex-wrap" key={key}>
        <Text emphasized color="secondary">
          {camelCase(label)}:
        </Text>
        {value}
      </div>
    );
  };

  return (
    <>
      {contactDetails.map((contactDetail, index) => {
        const label = contactDetail.label;

        if (
          label.toLowerCase() === "telefon" ||
          contactDetail.value.match(/^\+|\d/) != null
        ) {
          if (onlyWebsites) {
            return null;
          }

          const value = (
            <div onClick={e => e.stopPropagation()} key={index}>
              <a
                href={`tel:${contactDetail.value}`}
                target="_top"
                className="text-blue-500 transition-colors flex flex-row items-center gap-1 px-1.5 font-mono"
              >
                {contactDetail.value}
                <PhoneOutgoingIcon className="w-4 h-4" />
              </a>
            </div>
          );
          return showLabel
            ? valueWithLabelComponent(label, value, index)
            : value;
        }

        if (
          label.toLowerCase() === "email" ||
          contactDetail.value.includes("@") ||
          contactDetail.preview.includes("@")
        ) {
          if (onlyWebsites) {
            return null;
          }

          const targetValue =
            contactDetail.preview.length > 0
              ? contactDetail.preview
              : contactDetail.value;
          const value = (
            <div
              onClick={e => e.stopPropagation()}
              key={index}
              className="px-1.5 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <a
                href={`mailto:${targetValue}`}
                target="_top"
                className="text-blue-500 transition-colors flex flex-row items-center gap-1"
              >
                {targetValue}
                <MailIcon className="w-4 h-4" />
              </a>
            </div>
          );
          return showLabel
            ? valueWithLabelComponent(label, value, index)
            : value;
        }

        if (
          label.toLowerCase() === "website" ||
          contactDetail.value.startsWith("http")
        ) {
          const value = (
            <div key={index}>
              <OnLink
                onClick={e => e.stopPropagation()}
                href={contactDetail.value}
                rightIcon={<SquareArrowOutUpRightIcon className="w-4 h-4" />}
                value={contactDetail.preview}
                target="_blank"
                className="px-1.5"
              />
            </div>
          );
          return showLabel
            ? valueWithLabelComponent(label, value, index)
            : value;
        }

        if (onlyWebsites) {
          return null;
        }

        if (contactDetail.preview) {
          const value = <Text key={index}>{contactDetail.preview}</Text>;
          return showLabel
            ? valueWithLabelComponent(label, value, index)
            : value;
        }

        if (contactDetail.value) {
          const value = <Text key={index}>{contactDetail.value}</Text>;
          return showLabel
            ? valueWithLabelComponent(label, value, index)
            : value;
        }
      })}
    </>
  );
}
