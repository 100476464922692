import { Label } from "@radix-ui/react-label";
import classNames from "classnames";
import {
  CalendarPlus2Icon,
  ChevronLeftIcon,
  Clock4,
  HammerIcon,
  Plus,
  Trash,
  Trash2Icon,
} from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../common/AlertDialog";
import { Button, getButtonClass } from "../../common/Button";
import { Card } from "../../common/Card";
import { DatePicker } from "../../common/DatePicker";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../common/Dialog";
import TipTapEditor from "../../common/Editor/TipTapEditor";
import { ImageUpload } from "../../common/ImageUpload";
import { Input } from "../../common/Input";
import { SearchBox } from "../../common/SearchBox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../common/Select";
import {
  getPillColorFromStatus,
  getPilTextColorFromStatus,
  type OnStatus,
} from "../../common/StatusBadge";
import { Text } from "../../common/Text";
import { plural } from "../../utils/plural";
import { useWindowSize, WindowSize } from "../../utils/useWindowSize";
import { getNumberImputHandler } from "../../utils/utils";
import {
  entitiesClient,
  type OnEntitySearchResult,
} from "../Entity/entitiesClient";
import {
  EventEditingContextProvider,
  useEventEditingContext,
} from "./EventEditingContext";
import { useEvent } from "./EventsContext";
import type { OnEvent } from "./OnEvent";

const maxDurationValue = 24 * 60;
const defaultDurationValue = 60;

export function EventEditingPage() {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { currentEvent, loadingState, setCurrentEvent } = useEvent(eventId);

  useEffect(() => {
    if (loadingState === "ready" && eventId != null && currentEvent == null) {
      console.error("Event not found");
      navigate("/");
    }
  }, [currentEvent, eventId, loadingState, navigate]);

  if (loadingState === "loading") {
    return <div>Se incarca...</div>;
  }

  if (loadingState === "ready" && eventId != null && currentEvent == null) {
    return null;
  }

  const key = eventId != null ? eventId : "new-event";
  return (
    <EventEditingContextProvider
      key={`context-${key}`}
      eventToEdit={currentEvent}
      setUpdatedEvent={setCurrentEvent}
    >
      <EventEditingPageInner key={`page-${key}`} />
    </EventEditingContextProvider>
  );
}

function EventEditingPageInner() {
  const navigate = useNavigate();

  const {
    status,
    setStatus,
    name,
    setName,
    oneLine,
    setOneLine,
    description,
    setDescription,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    address,
    setAddress,
    locationOnMap,
    setLocationOnMap,
    links,
    setLinks,
    hasDraftState,
    isCreationDisabled,

    finalImagesOrder,
    existingImages,
    imagesToUpload,
    imagesToDelete,
    setImages,

    saveEvent,
  } = useEventEditingContext();

  const { eventId } = useParams();
  const isEditingEvent = eventId != null;
  const windowSize = useWindowSize();

  return (
    <div className="grid grid-cols-12 gap-2 py-4 mx-2 min-h-[calc(100vh-10rem)]">
      <SideMenu
        status={status}
        setStatus={setStatus}
        horizontal={windowSize !== WindowSize.Large}
      />
      <Card className="flex flex-col gap-2 col-span-full lg:col-start-4 lg:col-span-6 justify-between border border-orange-200 rounded-md shadow shadow-orange-100 bg-white">
        <div className="flex flex-col p-4 gap-y-2">
          <div className="flex flex-row gap-1 items-center pb-4">
            <CalendarPlus2Icon className="w-6 h-6" />
            <Text size="h4" emphasized>
              {isEditingEvent ? "Modifica eveniment" : "Adauga eveniment"}
            </Text>
          </div>
          {isEditingEvent ? (
            <div>
              <ImageUpload
                existingImages={existingImages}
                setImages={setImages}
                finalImagesOrder={finalImagesOrder}
                imagesToUpload={imagesToUpload}
                imagesToDelete={imagesToDelete}
              />
            </div>
          ) : null}
          <div>
            <Label htmlFor="oragnizer">Organizator</Label>
            <div className="flex flex-row gap-2">
              <EntitySearchBox />
              <CreateOrganizerDialog />
            </div>
          </div>
          <div>
            <Label htmlFor="name">Nume</Label>
            <Input
              id="name"
              className="w-full"
              placeholder="Denumire completa eveniment"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div>
            <div>
              <Label htmlFor="oneLine">Descriere scurta</Label>
              <Text color="secondary" size="small" className="ml-2">
                (optional)
              </Text>
            </div>
            <Input
              id="oneLine"
              placeholder="O fraza scurta de descriere a evenimentului"
              value={oneLine}
              onChange={e => setOneLine(e.target.value)}
              className="w-full"
            />
          </div>
          <EventDates
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
          <div className="flex flex-row flex-wrap gap-2">
            <div className="flex-grow">
              <Label htmlFor="location">Adresa</Label>
              <Input
                id="location"
                className="w-full"
                placeholder="Adresa locatiei evenimentului"
                value={address}
                onChange={e => setAddress(e.target.value)}
              />
            </div>
            <div className="flex-grow">
              <Label htmlFor="locationOnMap">Pin pe harta</Label>
              <Text color="secondary" size="small" className="ml-2">
                (ex: pin in Google Maps)
              </Text>
              <Input
                id="locationOnMap"
                type="url"
                className="w-full"
                placeholder="Link catre locatia evenimentului pe harta"
                value={locationOnMap}
                onChange={e => setLocationOnMap(e.target.value)}
              />
            </div>
          </div>
          <div>
            <Label htmlFor="description">Descriere</Label>
            <TipTapEditor
              editable={true}
              onBlur={setDescription}
              content={description}
              placeholder="Descrierea evenimentului / text de prezentare"
            />
          </div>
          <div className="flex-grow">
            <EventLinks links={links} setLinks={setLinks} />
          </div>
        </div>

        <div className="w-full flex flex-row-reverse justify-between gap-2 p-4">
          <Button
            value="Salveaza"
            disabled={isCreationDisabled}
            onClick={async () => {
              const savedEventId = await saveEvent();
              if (savedEventId != null) {
                navigate(`/iasi/evenimente/${savedEventId}`);
              }
            }}
          />
          <CancelDraftStateAlertDialog hasDraftState={hasDraftState} />
        </div>
      </Card>
    </div>
  );
}

export type EventLinkWithCategory = OnEvent["links"][0];
type EventLinksWithProps = {
  links: EventLinkWithCategory[];
  setLinks: (links: EventLinkWithCategory[]) => void;
};

function EventLinks({ links, setLinks }: EventLinksWithProps) {
  return (
    <>
      <Label htmlFor="location">Link-uri</Label>
      <div className="flex flex-col gap-y-2">
        {links.map((link, index) => {
          return (
            <EventLinkWithCategory
              key={index}
              linkEntry={link}
              setLinkEntry={updatedLink => {
                const updatedLinks = [...links];
                updatedLinks.splice(index, 1, updatedLink);
                setLinks(updatedLinks);
              }}
              remove={() => {
                const updatedLinks = [...links];
                updatedLinks.splice(index, 1);
                setLinks(updatedLinks);
              }}
            />
          );
        })}
        <div>
          <Button
            value="Adauga link"
            variant="outlineLight"
            icon={<Plus className="h-4 w-4" />}
            size="small"
            onClick={() => setLinks([...links, { category: "", url: "" }])}
          />
        </div>
      </div>
    </>
  );
}

type EventLinkWithCategoryProps = {
  linkEntry: EventLinkWithCategory;
  setLinkEntry: (linkEntry: EventLinkWithCategory) => void;
  remove: () => void;
};

function EventLinkWithCategory({
  linkEntry,
  setLinkEntry: setData,
  remove,
}: EventLinkWithCategoryProps) {
  const { category, url } = linkEntry;
  const isLinkInputDisabled = category == null || category === "";
  return (
    <div className="flex flex-row gap-2 flex-wrap">
      <div className="w-[200px]">
        <Input
          value={category}
          onChange={e => setData({ category: e.target.value, url })}
          placeholder={"Categorie"}
        />
      </div>
      <Input
        value={url}
        className="flex-grow"
        onChange={e => setData({ category, url: e.target.value })}
        placeholder={
          isLinkInputDisabled ? "Selecteaza o categorie pentru link" : "Link"
        }
        disabled={isLinkInputDisabled}
      />
      <div>
        <Button
          value="Elimina"
          variant="outlineLight"
          icon={<Trash className="h-4 w-4" />}
          onClick={remove}
        />
      </div>
    </div>
  );
}

type EventDatesProps = {
  startDate: Date | undefined;
  setStartDate: (date: Date | undefined) => void;
  endDate: Date | undefined;
  setEndDate: (date: Date | undefined) => void;
};

function EventDates({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: EventDatesProps) {
  const [showEndDate, setShowEndDate] = React.useState(endDate != null);
  const [duration, setDuration] = React.useState<number | undefined>();
  const [addEndDate, setAddEndDate] = React.useState(false);

  return (
    <div>
      <Label htmlFor="date" className="pt-2">
        Interval de desfasurare
      </Label>
      <div className="flex flex-row gap-x-6 gap-y-2 flex-wrap">
        <div>
          <Text color="secondary" className="min-h-[28px]">
            Incepere
          </Text>
          <DateTimePicker date={startDate} setDate={setStartDate} />
        </div>
        <div className="flex flex-row gap-x-4 gap-y-2 flex-wrap">
          {!addEndDate ? (
            <Button
              disabled={startDate === undefined}
              value="Mentioneaza incheiere"
              variant="outlineLight"
              size="small"
              onClick={() => {
                setAddEndDate(true);
                setShowEndDate(true);
              }}
            />
          ) : showEndDate ? (
            <div>
              <div className="flex flex-row justify-between items-center">
                <Text color="secondary">Incheiere</Text>
                <Button
                  variant="link"
                  value={"seteaza durata"}
                  size="small"
                  onClick={() => setShowEndDate(false)}
                />
              </div>
              <DateTimePicker
                fromDate={startDate}
                date={endDate}
                isEndRange={true}
                setDate={newDate => {
                  setEndDate(newDate);
                  if (newDate === undefined) {
                    setDuration(undefined);
                    return;
                  }
                  setDuration(
                    Math.round(
                      (newDate.getTime() - startDate!.getTime()) / 60000
                    )
                  );
                }}
              />
            </div>
          ) : (
            <div>
              <div className="flex flex-row justify-between items-center">
                <Text color="secondary">Durata</Text>{" "}
                <Button
                  variant="link"
                  value={"seteaza data"}
                  size="small"
                  onClick={() => setShowEndDate(true)}
                  className="ml-4"
                />
              </div>
              <div className="flex flex-row gap-2 align-middle items-center">
                <div className="flex flex-row items-center border border-input rounded-md gap-1 px-2">
                  <Clock4
                    className={`h-4 w-4 ${duration === undefined ? "text-gray-500" : ""}`}
                  />
                  <Input
                    id="event-duration"
                    hasBorder={false}
                    hasShadow={false}
                    value={duration}
                    onChange={e =>
                      getNumberImputHandler(
                        value => {
                          setDuration(value);
                          if (value === undefined) {
                            return;
                          }
                          setEndDate(
                            new Date(startDate!.getTime() + value * 60 * 1000)
                          );
                        },
                        { minValue: 0, maxValue: maxDurationValue }
                      )(e)
                    }
                    height="h-[34px]"
                    padding="p-1"
                    type="number"
                    className="w-[46px] max-w-auto on-hour-button text-center w-min-content"
                    placeholder={defaultDurationValue.toString()}
                    max={maxDurationValue}
                  />
                  <Text>
                    {plural(
                      duration ?? defaultDurationValue,
                      "minut",
                      "minute"
                    )}
                  </Text>
                </div>
              </div>
            </div>
          )}
          {addEndDate ? (
            <Button
              value="Nespecificat"
              variant="outlineLight"
              size="small"
              onClick={() => {
                setAddEndDate(false);
                setEndDate(undefined);
                setDuration(undefined);
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

type DateTimePickerProps = {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
  fromDate?: Date;
  isEndRange?: boolean;
};

function DateTimePicker({
  date,
  setDate,
  fromDate,
  isEndRange,
}: DateTimePickerProps) {
  const [hours, setHours] = React.useState<number | undefined>(
    date?.getHours() ?? (isEndRange ? 23 : undefined)
  );
  const [minutes, setMinutes] = React.useState<number | undefined>(
    date?.getMinutes() ?? (isEndRange ? 59 : undefined)
  );

  const [isMinuteInFocus, setIsMinuteInFocus] = React.useState(false);
  const [isHoursInFocus, setIsHoursInFocus] = React.useState(false);

  useEffect(() => {
    if (date != null) {
      if (hours == null && !isHoursInFocus) {
        setHours(date.getHours());
      }
      if (minutes == null && !isMinuteInFocus) {
        setMinutes(date.getMinutes());
      }
    } else {
      setHours(undefined);
      setMinutes(undefined);
    }
  }, [date, hours, isHoursInFocus, isMinuteInFocus, minutes]);

  return (
    <div className="flex flex-row gap-2 align-middle items-center">
      <DatePicker
        date={date}
        setDate={(newDate: Date | undefined) => {
          if (newDate == null) {
            setDate(newDate);
            return;
          }
          if (isEndRange && date == null) {
            newDate.setHours(23);
            newDate.setMinutes(59);
            newDate.setSeconds(59);
          } else {
            newDate.setHours(hours ?? 0);
            newDate.setMinutes(minutes ?? 0);
            newDate.setSeconds(0);
          }
          setDate(newDate);
        }}
        fromDate={fromDate}
      />
      <div className="flex flex-row items-center border border-input rounded-md gap-1">
        <Input
          id="event-start-hour"
          hasBorder={false}
          hasShadow={false}
          disabled={!date}
          value={isHoursInFocus ? hours : hours?.toString().padStart(2, "0")}
          onFocus={() => setIsHoursInFocus(true)}
          onBlur={() => setIsHoursInFocus(false)}
          onChange={getNumberImputHandler(
            newValue => {
              setHours(newValue);
              if (newValue !== undefined) {
                if (minutes === undefined) {
                  setMinutes(0);
                }
                const newDate = new Date(date!);
                newDate.setHours(newValue!);
                setDate(newDate);
              }
            },
            { minValue: 0, maxValue: 23 }
          )}
          height="h-[34px]"
          padding="p-1"
          type="number"
          min={0}
          max={23}
          className="w-[28px] on-hour-button text-center"
          placeholder={new Date().getHours().toString()}
        />
        <div className="pb-[2px]">:</div>
        <Input
          id="event-start-minute"
          hasBorder={false}
          hasShadow={false}
          disabled={!date}
          onFocus={() => setIsMinuteInFocus(true)}
          onBlur={() => setIsMinuteInFocus(false)}
          value={
            isMinuteInFocus ? minutes : minutes?.toString().padStart(2, "0")
          }
          onChange={getNumberImputHandler(
            newValue => {
              setMinutes(newValue);
              if (newValue !== undefined) {
                const newDate = new Date(date!);
                newDate.setMinutes(newValue!);
                setDate(newDate);
              }
            },
            { minValue: 0, maxValue: 59 }
          )}
          height="h-[34px]"
          padding="p-1"
          type="number"
          min={0}
          max={59}
          className="w-28px] on-hour-button text-center"
          placeholder={new Date().getMinutes().toString()}
        />
      </div>
    </div>
  );
}

type CancelDraftStateAlertDialogProps = { hasDraftState: boolean };

function CancelDraftStateAlertDialog({
  hasDraftState,
}: CancelDraftStateAlertDialogProps) {
  const navigate = useNavigate();

  // go back to the edited event or to the home page
  const navigationCallback = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  return (
    <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <AlertDialogTrigger asChild>
        <Button
          icon={<ChevronLeftIcon className="w-4 h-4" />}
          value="Inapoi"
          variant="outlineLight"
          onClick={() => {
            if (hasDraftState) {
              setIsDialogOpen(true);
            } else {
              navigationCallback();
            }
          }}
        />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Sigur renuntati la formular?</AlertDialogTitle>
          <AlertDialogDescription>
            Datele completate in formular vor fi pierdute si nu pot fi
            recuperate.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Continua completarea</AlertDialogCancel>
          <AlertDialogAction
            onClick={navigationCallback}
            className={getButtonClass({ variant: "warning" })}
          >
            Renunta
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function CreateOrganizerDialog() {
  const { setOrganizer } = useEventEditingContext();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [entityName, setEntityName] = React.useState("");

  const entityCreationCallback = useCallback(async () => {
    const newEntity = await entitiesClient.createEntity({
      name: entityName,
      description: "",
      media: [],
      contact: [],
    });
    setIsDialogOpen(false);
    setOrganizer(newEntity);
  }, [entityName, setOrganizer]);

  return (
    <Dialog open={isDialogOpen} onOpenChange={open => setIsDialogOpen(open)}>
      <DialogTrigger asChild>
        <Button
          value="Adauga"
          variant="outlineLight"
          icon={<HammerIcon className="h-4 w-4" />}
        />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Adauga organizator</DialogTitle>
          <DialogDescription>
            Adauga un nou organizator pentru evenimentul tau.
          </DialogDescription>
        </DialogHeader>
        <div>
          <Label htmlFor="name">Nume Organizator</Label>
          <Input
            id="name"
            className="w-full"
            placeholder="Denumire completa a organizatorului"
            value={entityName}
            onChange={e => setEntityName(e.target.value)}
          />
        </div>
        <DialogFooter>
          <Button value="Adauga" onClick={entityCreationCallback} />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function EntitySearchBox() {
  const { organizer, setOrganizer } = useEventEditingContext();

  const [results, setResults] = useState<OnEntitySearchResult[]>([]);

  const onValueChangeHandler = async (newSearchValue: string) => {
    const results = await entitiesClient.searchEntities(newSearchValue);
    setResults(results);
  };

  return (
    <>
      <SearchBox
        emptyResultsMessage="Nu s-a gasit organizator."
        searchBoxPlaceholder="Cauta organizator..."
        results={results.map(organizer => ({
          displayName: organizer.name,
          id: organizer.id,
        }))}
        selectedValue={
          organizer != null
            ? { id: organizer.id, displayName: organizer.name }
            : undefined
        }
        onValueChange={onValueChangeHandler}
        onValueSelection={(valueId: string) => {
          const selectedOrganizer = results.find(
            organizer => organizer.id === valueId
          );
          if (selectedOrganizer == null) {
            return;
          }
          setOrganizer(selectedOrganizer);
        }}
      />
      <Button
        value=""
        variant="outlineLight"
        onClick={() => setOrganizer(undefined)}
        icon={<Trash2Icon className="h-4 w-4" />}
      />
    </>
  );
}

type SideMenuProps = {
  setStatus: (status: OnEvent["status"]) => void;
  status: OnEvent["status"];
  horizontal?: boolean;
};

function SideMenu({ setStatus, status, horizontal }: SideMenuProps) {
  return (
    <div
      className={classNames(
        "flex gap-2",
        horizontal
          ? "col-span-full lg:col-start-4 lg:col-span-6 justify-end"
          : "col-start-10 row-start-1 row-span-1 flex-col justify-start items-start"
      )}
    >
      <div className="w-[120px]">
        <Select
          onValueChange={(newStatus: OnEvent["status"]) => setStatus(newStatus)}
          value={status}
        >
          <SelectTrigger
            backgroundClass={`${getPillColorFromStatus(status as unknown as OnStatus)} ${getPilTextColorFromStatus(status as unknown as OnStatus)}`}
            sizeClass="h-7"
          >
            <SelectValue placeholder={<Text color="secondary">Status</Text>} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="Draft" selectedValue={status}>
              Draft
            </SelectItem>
            <SelectItem value="Published" selectedValue={status}>
              Published
            </SelectItem>
            <SelectItem value="Archived" selectedValue={status}>
              Archived
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}
